<script>
import LogoutModal from '../modals/LogoutModal.vue'

export default {
  name: 'NavigationMenu',
  props: {
    isCollapsed: Boolean
  },
  components: {
    LogoutModal
  },
  data: function() {
    return {
      isLogoutModalVisible: false
    }
  },
  methods: {
    showLogoutModal: function () {
      this.isLogoutModalVisible = true
    },
    closeLogoutModal: function() {
      this.isLogoutModalVisible = false
    },
    onLogoutModalSubmit: function() {
      this.$router.push('/login')
    },
  }
}
</script>

<template>
  <div :class="{ 
      'navigation-menu': true, 
      'collapsed': this.isCollapsed 
    }">
    <ul class="navigation-list">
      <li class="with-sublink">
        <router-link to="/stations">
          <img src="../../assets/navigation/stations.svg">
          <span class="name">Stations</span>
        </router-link>
        <router-link class="sublink" to="/stations/add">
          <img src="../../assets/actions/add.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/station-models">
          <img src="../../assets/navigation/station-model.svg">
          <span class="name">Station models</span>
        </router-link>
        <router-link class="sublink" to="/station-models">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/evse-status">
          <img src="../../assets/navigation/evse-status.svg">
          <span class="name">EVSE Status</span>
        </router-link>
        <router-link class="sublink" to="/evse-status">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/meter-values">
          <img src="../../assets/navigation/meter-values.svg">
          <span class="name">Meter Values</span>
        </router-link>
        <router-link class="sublink" to="/meter-values">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/session-register">
          <img src="../../assets/navigation/session-register.svg">
          <span class="name">Session Register</span>
        </router-link>
        <router-link class="sublink" to="/session-register">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/ports">
          <img src="../../assets/navigation/ports.svg">
          <span class="name">Ports</span>
        </router-link>
        <router-link class="sublink" to="/ports">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/groups">
          <img src="../../assets/navigation/groups.svg">
          <span class="name">Groups</span>
        </router-link>
        <router-link class="sublink" to="/groups">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/utilization">
          <img src="../../assets/navigation/utilization.svg">
          <span class="name">Utilization</span>
        </router-link>
        <router-link class="sublink" to="/utilization">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/reservations">
          <img src="../../assets/navigation/reservations.svg">
          <span class="name">Reservations</span>
        </router-link>
        <router-link class="sublink" to="/reservations">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/groups-access-controls">
          <img src="../../assets/navigation/groups-access-controls.svg">
          <span class="name">Access Control</span>
        </router-link>
        <router-link class="sublink" to="/groups-access-controls">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/charging-profiles">
          <img src="../../assets/navigation/charge-profile.svg">
          <span class="name">Charging Profiles</span>
        </router-link>
        <router-link class="sublink" to="/charging-profiles/add">
          <img src="../../assets/actions/add.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/notifications">
          <img src="../../assets/navigation/notifications.svg">
          <span class="name">Notifications</span>
        </router-link>
        <router-link class="sublink" to="/notifications">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <router-link to="/simulator">
          <img src="../../assets/navigation/simulator.svg">
          <span class="name">Simulator</span>
        </router-link>
        <router-link class="sublink" to="/simulator">
          <img src="../../assets/actions/more.svg">
        </router-link>
      </li>
      <li>
        <button @click="showLogoutModal">
          <img src="../../assets/navigation/logout.svg">
          <span class="name">Sign out</span>
        </button>
      </li>
    </ul>
    <img class="navigation-logo" src="../../assets/argonne-logo.png">
    <logout-modal
      :visible="this.isLogoutModalVisible"
      @closed="closeLogoutModal()"
      @submit="onLogoutModalSubmit">
    </logout-modal>
  </div>
</template>

<style lang="scss" scoped>
.navigation-menu {
  box-shadow: -6px 4px 12px rgba(0, 0, 0, 0.7);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 56px;
  bottom: 0;
  width: 260px;
  transform: translateX(0);
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.collapsed {
    transform: translateX(-100%);
  }
}

.navigation-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  width: 100%;

  li {
    padding: 6px 0px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid rgba(245, 245, 245, 0.2);

    a:nth-child(1) {
      width: 90%;
    }

    a:hover,
    button:hover,
    a.router-link-active {
      opacity: 1;
    }

    a.router-link-active {
      font-weight: 800;
    }

    a,
    button {
      opacity: 0.7;
      padding: 8px 16px;
      margin: 0;
      color: #fff!important;
      text-decoration: none!important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
      border: 0px!important;
    }

    a img,
    button img {
      padding: 1px;
      margin-right: 12px;
      height: 20px;
      width: 20px;
    }

    a .name,
    button .name {
      height: 100%;
    }


    a.sublink {
      padding: 0px 12px;
    }

    a.sublink img {
      margin: 0px;
      padding: 0px;
      height: 20px;
      width: 20px;
    }
  }
}

.navigation-logo {
  margin: 30px auto;
  width: 80%;
}
</style>
