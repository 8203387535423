<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

export default {
  name: 'GroupAccessControlPage',
  data: function() {
    return {
      isLoading: true,
      groupAccessControls: null,
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    getGroupsAccessControls: async function() {
      this.groupAccessControls = await this.trackingService.getGroupsAccessControls()

      if(!this.groupAccessControls) {
        this.toastService.error('Failed to fetch Group Access Controls')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification, [
        'CHARGE_POINT_CONNECTED'
      ])
    },
    onIncomingNotification: function () {
      // update session registers
      this.getGroupsAccessControls()
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getGroupsAccessControls()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="groups-access-controls page">
    <div v-if="!groupAccessControls || groupAccessControls.length === 0" 
      class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>access controls</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="groups-table">
        <thead>
          <th>Access Control ID</th>
          <th>Group ID</th>
          <th>Group name</th>
          <th>Authorized Id</th>
        </thead>
        <transition-group tag="tbody" name="session-register-list" move-class="new">
          <tr v-for="groupAccessControl of groupAccessControls" 
            :key="groupAccessControl.id">
            <td>{{ groupAccessControl.id }}</td>
            <td>{{ groupAccessControl.groupId }}</td>
            <td>{{ groupAccessControl.groupName }}</td>
            <td>{{ groupAccessControl.authorizedId }}</td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.session-register.page {
  padding: 0px!important;
  width: 100%;
}
</style>
