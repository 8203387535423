'use strict'

import ApiService from '../ApiService'

export default class SimulatorService extends ApiService {
  async start () {
    return this._sendRequest({
      url: '/simulator/start'
    })
  }

  async stop () {
    return this._sendRequest({
      url: '/simulator/stop'
    })
  }

  async getStatus () {
    return this._sendRequest({
      url: '/simulator/status'
    })
  }
}
