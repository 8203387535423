<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

export default {
  name: 'SessionRegisterPage',
  data: function() {
    return {
      isLoading: true,
      sessionRegisters: null,
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    getSessionRegisters: async function() {
      this.sessionRegisters = await this.trackingService.getSessionRegisters()

      if(!this.sessionRegisters) {
        this.toastService.error('Failed to fetch Session Registers')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification, [
        'CHARGE_POINT_CONNECTED'
      ])
    },
    onIncomingNotification: function () {
      // update session registers
      this.getSessionRegisters()
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getSessionRegisters()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="session-register page">
    <div v-if="!sessionRegisters || sessionRegisters.length === 0" 
      class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>session registers</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="session-register-table">
        <thead>
          <th>Created At</th>
          <th>Chargebox ID</th>
          <th>Connector ID</th>
          <th>ID Tag</th>
          <th>Transaction ID</th>
        </thead>
        <transition-group tag="tbody" name="session-register-list" move-class="new">
          <tr v-for="sessionRegister of sessionRegisters" 
            :key="sessionRegister.time">
            <td>{{ $moment(sessionRegister.time).format("MM/DD/YYYY HH:mm:ss") }}</td>
            <td>{{ sessionRegister.chargeboxID }}</td>
            <td>{{ sessionRegister.connectorID }}</td>
            <td>{{ sessionRegister.idTag }}</td>
            <td>{{ sessionRegister.transactionID }}</td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.session-register.page {
  padding: 0px!important;
  width: 100%;
}
</style>
