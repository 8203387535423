<script>
import StationsService from '../../../services/api/ocpp/StationsService'

export default {
  name: 'SetChargingScheduleModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      connectorId: '',
      connectorIdState: null,
      chargingProfileId: '',
      chargingProfileIdState: null,
      chargingProfileOptions: [],
      stationsService: new StationsService(this)
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      this.connectorIdState = valid ? true : false
      this.chargingProfileIdState = valid ? true : false
      return valid
    },
    resetModal: function() {
      this.connectorId = ''
      this.connectorIdState = null
      this.chargingProfileId = ''
      this.chargingProfileIdState = null
      this.$emit("closed")
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
        
      this.$emit("submit", {
        connectorId: this.connectorId,
        chargingProfileId: this.chargingProfileId
      })

      this.$nextTick(() => {
        this.$refs.setChargingScheduleModal.hide()
      })
    }
  },
  async created() {
    for (const profile of await this.stationsService.getSmartChargingProfiles()) {
      this.chargingProfileOptions.push({
        text: `${profile.name} (${profile.id})`,
        value: profile.id
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.setChargingScheduleModal.show()
      } else {
        this.$refs.setChargingScheduleModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="set-charging-schedule modal">
    <b-modal
      id="set-charging-schedule"
      ref="setChargingScheduleModal"
      title="Set Charging Profile"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="connectorIdState"
          label="Connector ID*"
          label-for="connector-id-input"
          invalid-feedback="Connector ID is required">
          <b-form-input
            id="connector-id-input"
            v-model="connectorId"
            :state="connectorIdState"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="chargingProfileIdState"
          label="Charging Profile*"
          label-for="charging-profile-id-input"
          invalid-feedback="Charging Profile is required">
          <b-form-select
            id="charging-profile-id-input"
            v-model="chargingProfileId"
            :state="chargingProfileIdState"
            :options="chargingProfileOptions"
            required>
          </b-form-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>