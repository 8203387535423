<script>
export default {
  name: 'NavigationBar',
  props: {
    onNavigationToggle: Function,
    onNotificationChannelToggle: Function,
    isNavigationCollapsed: Boolean,
    isNotificationsChannelCollapsed: Boolean
  },
  data: () => {
    return {
      pageTitle: document.title,
      isBackButtonVisible: false,
      currentLocation: null,
      previousLocation: null
    };
  },
  methods: {
    goBack: function() {
      this.$router.push(this.previousLocation)
    },
    toggleNavigation: function() {
      this.onNavigationToggle(!this.isNavigationCollapsed)
    },
    toggleNotificationsChannel: function() {
      this.onNotificationChannelToggle(!this.isNotificationsChannelCollapsed)
    }
  },
  watch: {
    $route (to, from) {
      this.pageTitle = to.meta.title

      if(to.path !== from.path) {
        this.currentLocation = to.path
        this.previousLocation = from.path

        if(this.currentLocation.substring(1).includes("/")) {
          this.isBackButtonVisible = true
        } else {
          this.isBackButtonVisible = false
        }
      }
    }
  } 
}
</script>

<template>
  <div :class="{ 
      'navigation-bar': true, 
      'extended-left': this.isNavigationCollapsed,
      'extended-right': this.isNotificationsChannelCollapsed 
    }">
    <div class="left-section">
      <div class="navigation-menu-toggle">
        <div class="wrapper">
          <button @click="toggleNavigation"
            :class="{
              'nav': true,
              'open': !isNavigationCollapsed
            }">
            <span class="first-line"></span>
            <span class="second-line"></span>
            <span class="third-line"></span>
          </button>
        </div>
      </div>
      <div class="logo-container">
        <h2 class="logo">OCPP CSMS UI</h2>
        <h4 class="logo-subtitle">
          <span class="app-name">Dashboard</span>
          <span class="logo-version">v1.4.0</span>
        </h4>
      </div>
      <div class="logo-image-container">
        <img src="../../assets/logo.png">
      </div>
    </div>
    <div class="main-section">
      <h2 class="app-title">
        <button @click="goBack" :class="{
            'hidden': !isBackButtonVisible
          }">
          <img src="../../assets/actions/back.svg">
        </button>
        <span class="page-title">{{ this.pageTitle }}</span>
      </h2>
    </div>
    <button class="btn-alert" @click="toggleNotificationsChannel">
      <img 
        v-if="isNotificationsChannelCollapsed" 
        src="../../assets/navigation/notifications_on.svg">
      <img 
        v-else 
        src="../../assets/navigation/notifications_off.svg">
    </button>
  </div>
</template>

<style lang="scss" scoped>
.btn-alert { 
  margin: 0px 12px!important;
}

.navigation-bar {
  background-color: rgb(29, 57, 93);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 1);
  height: 56px;
  z-index: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  transition: all 0.3s linear;
  position: sticky;
  top: 0;
  margin-right: 260px;
  left: 0px;

  .left-section {
    width: 261px;
    min-width: 261px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background-color: #1e262f;
    border-right: 1px solid #000000;

    .logo-container {
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 8px 4px;
      border-top: 1px solid rgba(245, 245, 245, 0.2);
      padding-top: 10px;
      transform: scale(0.85) translateZ(0);
      height: 100%;
      border: 0px;
      position: relative;
      left: -12px;
      top: 1px;

      .logo {
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        margin: 0px auto;
        padding: 0px;
        width: 100%;
        margin-bottom: -8px;
      }

      .logo-subtitle {
        margin: 0px auto;
        padding: 0px;
        width: 100%;
      }

      .app-name {
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        margin-right: 8px;
        opacity: 0.8;
      }

      .logo-version {
        color: #97c4ff;
        font-weight: 700;
        font-size: 12px;
      }
    }

    .logo-image-container {
      height: 80%;
      border: 0px;
      position: relative;
      margin-right: -30px;

      img {
        height: 95%;
      }
    }
  }

  .main-section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &.extended-left {
    left: 0;
  }

  &.extended-right {
    right: 0;
    margin-right: 0px;
  }

  .app-title {
    color: #f5f5f5;
    font-size: 16px;
    padding: 12px;
    font-weight: 800;
    margin: 0px!important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 2px;

      &.hidden {
        display: none;
      }
    }

    a {
      font-size: 16px;
      color: #fff;
      text-decoration: none!important;
      height: 100%;
      margin: 0px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .page-title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 0px 12px;
    margin: 0px auto;
  }

  button {
    background-color: transparent!important;
    border: 0px;
    box-shadow: 0px!important;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    img {
      height: 20px;
      width: 20px;
    }
  }
}


.wrapper{
  width:100px;
  width: 40%;
  height: 40%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: scale(0.4) translateZ(0);
  position: relative;
  left: -24px;
  top: 0;
}

.nav{
  width:56px;
  height:56px;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  background-color:transparent;
  border-style:none;
  outline: none;
  cursor: pointer;
  margin-top: 9px;

  &.open {
    margin-top: 0px;
  }
}

.nav span{
  width:100%;
  height:5px;
  background-color:#fff;
  display:block;
  position: absolute;
  border-radius: 2px;
  transition: all .5s ease;
}

.nav span:nth-child(1){
  top:0px;
  left:0px;
}

.nav span:nth-child(2){
  top:20px;
  right:0px;
}

.nav span:nth-child(3){
  top:40px;
  left:0px;
}

.nav.open span:nth-child(2){
  animation: span2 1.5s forwards cubic-bezier(.8, .5, .2, 1.4);
}

@keyframes span2{
  0%{
      transform: translate(0,0) scale(1);
  }

  20%{
      width:5px;
      border-radius: 50%;
      transform: translate(0,0) scale(1);
  }

  40%{
      width:5px;
      border-radius: 50%;
      transform: translate(0,0) scale(1);
  }

  50%{
      width:5px;
      border-radius: 50%;
      transform: translate(0,18px) scale(1); 
  }

  80%{
      width:5px;
      border-radius: 50%;
      transform: translate(0,-16px) scale(1);
  }

  100%{
      width:5px;
      border-radius: 50%;
      transform: translate(0,100px) scale(0);
  }
}

.nav.open span:nth-child(3){
  animation: span3 .8s forwards cubic-bezier(.8, .5, .2, 1.4);
  animation-delay: .7s;
}

@keyframes span3{
  0%{
      transform: translate(0,0);
  }

  60%{
      transform: rotate(80deg) translate(-7px, -7px);
  }

  100%{
      transform: rotate(45deg) translate(-7px, -7px);
  }
}

.nav.open span:nth-child(1){
  animation: span1 .8s forwards cubic-bezier(.8, .5, .2, 1.4);
  animation-delay: 1.1s;
}

@keyframes span1{
  0%{
      transform: translate(0,0);
  }

  60%{
      transform: rotate(-110deg) translate(-7px, -7px);
  }

  100%{
      transform: rotate(-45deg) translate(-20px, 20px);
  }

}   

.nav span:nth-child(1){
  animation: span1_close .8s forwards cubic-bezier(.8, .5, .2, 1.4);
}

@keyframes span1_close {
  0%{
      transform: rotate(-45deg) translate(-20px, 20px);
  }
  
  100%{
      transform: rotate(0deg) translate(0, 0);
  }
}

.nav span:nth-child(2){
  animation: span2_close .8s forwards cubic-bezier(.8, .5, .2, 1.4);
}

@keyframes span2_close{
  0%{
      transform: translate(0, 40px) scale(0);
  }

  100%{
      transform: translate(0, 0) scale(1);
  }
}

.nav span:nth-child(3){
    animation: span3_close .8s forwards cubic-bezier(.8, .5, .2, 1.4);
}

@keyframes span3_close{
  0%{
      transform: rotate(45deg) translate(-7px, -7px);
  }
  100%{
      transform: rotate(0deg) translate(0, 0);
  }
}
</style>
