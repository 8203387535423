<script>
export default {
  name: 'DeleteStationModal',
  props: {
    visible: Boolean,
    onCollapse: Function,
    station: Object
  },
  methods: {
    handleDelete: function() {
      this.$emit("submit")
    },
    handleClose: function() {
      this.$emit("closed")
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.deleteStationModal.show()
      } else {
        this.$refs.deleteStationModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="delete-station modal">
    <b-modal
      id="delete-station-modal"
      ref="deleteStationModal"
      title="Delete station"
      okTitle="Delete"
      cancelTitle="Cancel"
      footerClass="delete-station-modal-footer"
      @cancel="handleClose"
      @close="handleClose"
      @ok="handleDelete">
      <div v-if="station" class="modal-body">
        <p>Are you sure you want to delete station
          <strong> {{ this.station.name }}</strong>?
        </p>
      </div>
    </b-modal>
  </div>
</template>