<script>
export default {
  name: 'CancelStationEditModal',
  props: {
    visible: Boolean,
    onCollapse: Function,
    station: Object
  },
  methods: {
    handleCancel: function() {
      this.$emit("submit")
    },
    handleClose: function() {
      this.$emit("closed")
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.cancelStationEditModal.show()
      } else {
        this.$refs.cancelStationEditModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="cancel-station-edit modal">
    <b-modal
      id="cancel-station-edit-modal"
      ref="cancelStationEditModal"
      title="Cancel edit"
      okTitle="Cancel"
      cancelTitle="Keep open"
      footerClass="cancel-station-edit-modal-footer"
      @cancel="handleClose"
      @close="handleClose"
      @ok="handleCancel">
      <div class="modal-body">
        <p>Are you sure you want to cancel without saving changes?</p>
      </div>
    </b-modal>
  </div>
</template>