<script>
export default {
  name: 'ChangeAvailabilityModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      connectorId: '',
      connectorIdState: null,
      availabilityType: '',
      availabilityTypeState: null
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      this.connectorIdState = valid
      this.availabilityTypeState = valid
      return valid
    },
    resetModal: function() {
      this.connectorId = ''
      this.connectorIdState = null
      this.$emit("closed")
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
        
      this.$emit("submit", {
        connectorId: this.connectorId,
        availabilityType: this.availabilityType
      })

      this.$nextTick(() => {
        this.$refs.changeAvailabilityModal.hide()
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.changeAvailabilityModal.show()
      } else {
        this.$refs.changeAvailabilityModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="change-availability modal">
    <b-modal
      id="change-availability-modal"
      ref="changeAvailabilityModal"
      title="Send change availability request"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="connectorIdState"
          label="Connector ID*"
          label-for="connector-id-input"
          invalid-feedback="Connector ID is required">
          <b-form-input
            id="connector-id-input"
            v-model="connectorId"
            :state="connectorIdState"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="availabilityTypeState"
          label="Availability Type*"
          label-for="availability-type"
          invalid-feedback="AvailabilityType is required">
          <b-form-select
            id="availability-type"
            v-model="availabilityType"
            :state="availabilityTypeState"
            required>
            <option value="Operative">
              Operative
            </option>
            <option value="Inoperative">
              Inoperative
            </option>
          </b-form-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>