'use strict'

export default class CacheService {

setCache(key, data, ttl = 3 * 60 * 1000) { // cached for 3 minutes in miliseconds
    const now = Date.now();
    const item = {
        data: data,
        expiry: now + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

getCache(key) {
    const itemStr = localStorage.getItem(key);
    // If the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = Date.now();
    // Compare the expiry time of the item with the current time
    if (now > item.expiry) {
        // If the item is expired, delete the item from storage and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.data;
}

}
