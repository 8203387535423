'use strict'

import ApiService from '../ApiService'

export default class OcppService extends ApiService {
  async connect (stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/connect`
    })
  }

  async disconnect (stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/disconnect`
    })
  }

  async changeAvailability (stationId, connectorId, availabilityType) {
    return this._sendRequest({
      url: `/stations/${stationId}/ports/${connectorId}/changeAvailability`,
      method: 'GET',
      params: { availabilityType }
    })
  }

  async triggerMessage (stationId, requestedMessage, portId) {
    return this._sendRequest({
      url: `/stations/${stationId}/trigger`,
      method: 'GET',
      params: { requestedMessage, portId }
    })
  }

  async clearCache (stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/clearCache`
    })
  }

  async getConfiguration (stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/configuration`
    })
  }

  async changeConfiguration (stationId, key, value) {
    return this._sendRequest({
      url: `/stations/${stationId}/configuration/change`,
      method: 'GET',
      params: {
        key: key,
        value: value
      }
    })
  }

  async unlockConnector (stationId, connectorId) {
    return this._sendRequest({
      url: `/stations/${stationId}/ports/${connectorId}/unlock`
    })
  }

  async dataTransfer (stationId, vendorId, messageId, data) {
    return this._sendRequest({
      url: `/stations/${stationId}/${vendorId}/dataTransfer`,
      params: {
        messageId,
        data
      }
    })
  }

  async remoteStartTransaction (stationId, connectorId, chargingProfileId) {
    return this._sendRequest({
      url: `/stations/${stationId}/ports/${connectorId}/startTransaction`,
      method: 'GET',
      params: {
        chargingProfileId
      }
    })
  }

  async remoteStopTransaction (stationId, connectorId, transactionId = null) {
    return this._sendRequest({
      url: `/stations/${stationId}/ports/${connectorId}/stopTransaction`, 
      params: {
        transactionId
      }
    })
  }

  async setChargingProfile (stationId, connectorId, chargingProfileId) {
    return this._sendRequest({
      url: `/stations/${stationId}/ports/${connectorId}/chargingProfile/set`,
      method: 'PUT',
      params: {
        chargingProfileId
      }
    })
  }

  async getChargingProfile (stationId, connectorId, duration, chargingRateUnit) {
    return this._sendRequest({
      url: `/stations/${stationId}/ports/${connectorId}/chargingProfile/get`,
      params: {
        duration,
        chargingRateUnit
      }
    })
  }

  async clearChargingProfile (stationId, id, portId, chargingProfilePurpose, stackLevel) {
    return this._sendRequest({
      url: `/stations/${stationId}/chargingProfile/clear`,
      params: {
        id, portId, chargingProfilePurpose, stackLevel
      }
    })
  }

  async softReset (stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/reset/soft`
    })
  }

  async hardReset (stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/reset/hard`
    })
  }
}
