'use strict'

import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'

import ApiService from '../ApiService'

export default class NotificationsService extends ApiService {
  constructor (vueObject) {
    super(vueObject)
    this.connectToChannel()
  }

  connectToChannel () {
    if (!this.isChannelConnected) {
      this.socket = new SockJS(`${this.config.VUE_APP_API_URL}/notifications-websocket`)
      this.stompClient = Stomp.over(this.socket)
      this.stompClient.connect(
        {},
        () => {
          this.isChannelConnected = true
          this.stompClient.subscribe(
            '/notifications/channel',
            tick => {
              this.onIncomingNotification(tick)
            }
          )
        },
        () => {
          // eslint-disable-next-line no-console
          console.warn('Notifications channel disconnected')
          this.isChannelConnected = false
        }
      )
    }
  }

  onIncomingNotification (tick) {
    if (tick && tick.body) {
      const notification = JSON.parse(tick.body)

      const callbacksToTrigger = (this.callbacks || [])
        .filter(cb => !cb.actions || !cb.actions.length ||
          cb.actions.includes(notification.type))

      if (callbacksToTrigger && callbacksToTrigger.length) {
        callbacksToTrigger.forEach(cb => cb.callback(notification))
      }
    }
  }

  registerCallback (callback, actions = []) {
    this.callbacks = this.callbacks || []
    this.callbacks.push({
      callback,
      actions
    })
  }

  async getNotifications (params = {}) {
    return this._sendRequest({
      url: '/notifications',
      params
    })
  }

  async getNotificationById (notificationId) {
    return this._sendRequest({
      url: `/notifications/${notificationId}`,
      method: 'GET'
    })
  }
}
