import LoginPage from '@/pages/login/LoginPage'

import StationsListPage from '@/pages/station/StationListPage'
import StationEditPage from '@/pages/station/StationEditPage'
import StationDetailsPage from '@/pages/station/StationDetailsPage'

import StationModelListPage from '@/pages/station-model/StationModelListPage'

import NotificationsListPage from '@/pages/notification/NotificationListPage'
import NotificationDetailsPage from '@/pages/notification/NotificationDetailsPage'

import EvseStatusListPage from '@/pages/evse-status/EvseStatusListPage'
import EvseStatusDetailsPage from '@/pages/evse-status/EvseStatusDetailsPage'

import MeterValuesPage from '@/pages/meter-values/MeterValuesPage'
import SessionRegisterPage from '@/pages/session-register/SessionRegisterPage'
import PortListPage from '@/pages/port/PortListPage'

import SimulatorPage from '@/pages/simulator/SimulatorPage'

import GroupListPage from '@/pages/groups/GroupsPage'
import GroupAccessControlListPage from '@/pages/groups-access-controls/GroupsAccessControlsPage'

import ChargingProfileListPage from '@/pages/charging-profile/ChargingProfileListPage'
import ChargingProfileEditPage from '@/pages/charging-profile/ChargingProfileEditPage'

import UtilizationPage from '@/pages/utilization/UtilizationPage'
import ReservationsPage from '@/pages/reservations/ReservationsPage'
import ReservationDetailsPage from '@/pages/reservations/ReservationDetailsPage'

function authenticate (next) {
  if (doesUserExists()) {
    next()
  } else {
    next({
      name: 'login'
    })
  }
}

function doesUserExists () {
  return window.localStorage.getItem('user')
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      title: 'Login'
    },
    beforeEnter: (to, from, next) => {
      if (doesUserExists()) {
        next({
          name: 'default'
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/',
    name: 'default',
    component: StationsListPage,
    meta: {
      title: 'Stations'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/stations',
    name: 'stations',
    component: StationsListPage,
    meta: {
      title: 'Stations'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/stations/add',
    name: 'station-add',
    component: StationEditPage,
    meta: {
      title: 'Add station'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/station-models',
    name: 'station-models-list',
    component: StationModelListPage,
    meta: {
      title: 'Station models'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/stations/:id',
    name: 'station-details',
    component: StationDetailsPage,
    meta: {
      title: 'Station details'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/stations/:id/edit',
    name: 'station-edit',
    component: StationEditPage,
    meta: {
      title: 'Edit station'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/simulator',
    name: 'simulator',
    component: SimulatorPage,
    meta: {
      title: 'OCPP station simulator'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsListPage,
    meta: {
      title: 'Notifications'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/notifications/:id',
    name: 'notification',
    component: NotificationDetailsPage,
    meta: {
      title: 'Notification details'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/evse-status',
    name: 'evse-status',
    component: EvseStatusListPage,
    meta: {
      title: 'EVSE status'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/evse-status/:chargeboxId/:connectorId',
    name: 'evse-status-details',
    component: EvseStatusDetailsPage,
    meta: {
      title: 'EVSE status details'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/meter-values',
    name: 'meter-values',
    component: MeterValuesPage,
    meta: {
      title: 'Meter values'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/session-register',
    name: 'session-register',
    component: SessionRegisterPage,
    meta: {
      title: 'Session register'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/ports',
    name: 'ports',
    component: PortListPage,
    meta: {
      title: 'Ports'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/groups',
    name: 'groups',
    component: GroupListPage,
    meta: {
      title: 'Groups'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/groups-access-controls',
    name: 'groups-access-controls',
    component: GroupAccessControlListPage,
    meta: {
      title: 'Groups access control'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/charging-profiles',
    name: 'charging-profile',
    component: ChargingProfileListPage,
    meta: {
      title: 'Charging Profiles'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/charging-profiles/add',
    name: 'charging-profiles-add',
    component: ChargingProfileEditPage,
    meta: {
      title: 'Add Charging Profile'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/charging-profiles/:id',
    name: 'charging-profile-edit',
    component: ChargingProfileEditPage,
    meta: {
      title: 'Edit Charging Profile'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/utilization',
    name: 'utilization',
    component: UtilizationPage,
    meta: {
      title: 'Station Utilization'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: ReservationsPage,
    meta: {
      title: 'Reservations'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  {
    path: '/reservations/:id',
    name: 'reservation-details',
    component: ReservationDetailsPage,
    meta: {
      title: 'Reservation details'
    },
    beforeEnter: (to, from, next) => authenticate(next)
  },
  
]

export default routes
