<script>
import ToastService from '../../services/ToastService'

export default {
  name: 'NotificationsListPage',
  props: [ 'notificationsService' ],
  data: function() {
    return {
      filters: {
        date: null,
        status: null,
        type: null,
        title: null
      },
      paginationConfig: {
        currentPage: 1,
        totalPages: 10,
        itemsPerPage: 50
      },
      isLoading: false,
      toastService: new ToastService(this.$toast),
      notifications: []
    }
  },
  methods: {
    getNotifications: async function() {
      this.isLoading = true

      this.notifications = await this.notificationsService.getNotifications()

      if(!this.notifications) {
        this.toastService.error('Failed to load notifications')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification)
    },
    onIncomingNotification: function (notification) {
      if(!this.notifications) {
        this.notifications = []
      }

      this.notifications.unshift(notification)
    },
    linkGen: function (newPage) {
      return `?page=${newPage}`
      // this.paginationConfig.currentPage = newPage
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getNotifications()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="notifications-list page">
    <div v-if="!notifications || notifications.length === 0" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>notifications</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="notifications-table">
        <thead>
          <th>Created At</th>
          <th class="center">Status</th>
          <th class="center">Direction</th>
          <th class="left">Title</th>
          <th>Info</th>
        </thead>
        <transition-group tag="tbody" name="notification-list" move-class="new">
          <tr v-for="notification of notifications" 
            :key="notification.id">
            <td>{{ $moment(notification.date).format("MM/DD/YYYY HH:mm:ss") }}</td>
            <td class="center">
              <b-badge :class="notification.status.toLowerCase()">
              {{ notification.status.toUpperCase().replace('_',' ') }}
              </b-badge>
            </td>
            <td class="center">
              <b-badge :class="'pill notification-direction-' + (notification.direction || 'SYSTEM').toLowerCase().replace('none', 'system').replace('_','-')">
               <img src="../../assets/system.svg" v-if="!notification.direction || notification.direction.toUpperCase() === 'NONE' || notification.direction.toUpperCase() === 'SYSTEM'">
               <img src="../../assets/inbound.svg" v-if="notification.direction && notification.direction.toUpperCase() === 'INBOUND'">
               <img src="../../assets/outbound.svg" v-if="notification.direction && notification.direction.toUpperCase() === 'OUTBOUND'">
                {{ (notification.direction || "SYSTEM").toUpperCase().replace("NONE", "SYSTEM") }}
              </b-badge>
            </td>
            <td class="left">{{ notification.title }}</td>
            <td>
              <router-link :to="'/notifications/' + notification.id"
              class="text-info">
                <img class="action-btn" src="../../assets/actions/info.svg">
              </router-link>
            </td>
          </tr>
        </transition-group>
      </table>
      <!--
      <div class="page-navigator">
        <div class="pagination-container">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="paginationConfig.totalPages" use-router></b-pagination-nav>
          <b-dropdown text="Items per page (20)" class="items-per-page m-md-2">
            <b-dropdown-item>20</b-dropdown-item>
            <b-dropdown-item>50</b-dropdown-item>
            <b-dropdown-item>100</b-dropdown-item>
          </b-dropdown>
        </div>
        <b-button class="filters">Show filters</b-button>
      </div>
    -->
    </div>
  </div>
</template>

<style lang="scss">
.notifications-list.page {
  padding: 0px!important;
  width: 100%;
}

.pagination {
  margin-bottom: 0px!important;
  padding: 0!important;
}

.page-navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1d395d;
  color: #fff;
  border-radius: 0px!important;
  z-index: 1000;
  position: sticky;
  padding: 6px 12px;
  margin: 0 auto!important;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 14px!important;

  .pagination-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .items-per-page {
      button {
        background-color: transparent!important;
        border-color: #fff;
        color: #fff;
        font-size: 14px!important;
      }
    }
  }

  .filters {
    font-size: 14px!important;
    background-color: transparent!important;
    border-color: #fff;
    color: #fff;
  }
}
</style>
