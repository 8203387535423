<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import StationsService from '../../services/api/ocpp/StationsService'
import ToastService from '../../services/ToastService'

export default {
  name: 'PortListPage',
  props: [ 'notificationsService' ],
  data: function() {
    return {
      isLoading: true,
      ports: null,
      stations: null,
      connectors: null,
      connectors_map: {},
      trackingService: new TrackingService(this),
      stationsService: new StationsService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    getPorts: async function() {
      this.ports = await this.trackingService.getPorts()

      if (!this.ports) {
        this.toastService.error('Failed to fetch ports')
      }
    },
    getStations: async function() {
      if (!this.ports)
        return

      const stations = await this.stationsService.getStations()

      if (!stations)
        return

      this.stations = {}
      stations.forEach(station => {
        this.stations[station.id] = station
      })
    },
    getConnectors: async function() {
      if (!this.ports || !this.stations)
        return
      this.connectors = this.ports.map(port => ({
        id: port.id,
        stationId: port.stationId,
        chargeboxId: this.stations[port.stationId].chargeboxID,
        name: port.name,
        connectorId: port.connectorId,
        adapterAttached: port.adapterAttached,
        status: port.connectorStatus,
      }))
      this.connectors.forEach(connector => {
        this.connectors_map[connector.stationId + connector.connectorId] = connector
      })
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification)
    },
    onIncomingNotification: function (notification) {
      switch(notification.type) {
        case 'RECEIVED_STATUS_NOTIFICATION_REQUEST':
          this.setStatus(notification)
          break
      }
    },
    setStatus: function (notification) {
      const payload = JSON.parse(notification.payload)
      const key = notification.stationId + payload.request.connectorId
      if (key in this.connectors_map) {
        this.connectors_map[key]['status'] = payload.request.status
      }
    }
  },
  async mounted() {
    this.isLoading = true
    this.connectToChannel()
    await this.getPorts()
    await this.getStations()
    await this.getConnectors()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="ports page">
    <div v-if="!connectors || connectors.length === 0" 
      class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>ports</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="ports-table">
        <thead>
          <th>Chargebox ID</th>
          <th class="left">Name</th>
          <th>Connector ID</th>
          <th>Status</th>
          <th>Connector Attached</th>
          <th>Info</th>
        </thead>
        <tbody>
          <tr v-for="connector of connectors" 
            :key="connector.id">
            <td>{{ connector.chargeboxId }}</td>
            <td class="left">{{ connector.name }}</td>
            <td>{{ connector.connectorId }}</td>
            <td>
              <b-badge v-if="connector.status" 
                :class="'pill station-status-' + connector.status.toLowerCase().replace('_','-')">
                {{ connector.status.toUpperCase().replace('_',' ') }}
              </b-badge>
              <b-badge v-else
                :class="'pill station-status-unavailable'">
                UNAVAILABLE
              </b-badge>
            </td>
            <td>
                <img v-if="connector.adapterAttached" src="../../assets/checked.svg">
                <img v-else src="../../assets/unchecked.svg">
            </td>
            <td>
              <router-link 
                :to="`/evse-status/${connector.chargeboxId}/${connector.connectorId}`"
                class="text-info">
                <img class="action-btn" src="../../assets/actions/info.svg">
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ports.page {
  padding: 0px!important;
  width: 100%;
}
</style>
