<script>
export default {
  name: 'DataTransferModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      vendorId: '',
      vendorIdState: null,
      messageId: '',
      messageIdState: null,
      data: '',
      dataState: null
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      this.vendorIdState = valid ? true : false
      return valid
    },
    resetModal: function() {
      this.vendorId = '',
      this.vendorIdState = null,
      this.messageId = '',
      this.messageIdState = null,
      this.data = '',
      this.dataState = null
      this.$emit("closed");
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
      
      this.$emit("submit", {
        vendorId: this.vendorId,
        messageId: this.messageId,
        data: this.data
      })

      this.$nextTick(() => {
        this.$refs.dataTransferModal.hide()
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.dataTransferModal.show()
      } else {
        this.$refs.dataTransferModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="data-transfer modal">
    <b-modal
      id="data-transfer-modal"
      ref="dataTransferModal"
      title="Send data transfer request"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" 
        @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="vendorIdState"
          label="Vendor ID*"
          label-for="vendor-id-input"
          invalid-feedback="Vendor ID is required">
          <b-form-input
            id="vendor-id-input"
            v-model="vendorId"
            :state="vendorIdState"
            required>
          </b-form-input>
        </b-form-group>
         <b-form-group
          :state="messageIdState"
          label="Message ID"
          label-for="mesage-id-input">
          <b-form-input
            id="mesage-id-input"
            v-model="messageId"
            :state="messageIdState">
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="dataState"
          label="Data"
          label-for="data-input">
          <b-form-input
            id="data-input"
            v-model="data"
            :state="dataState">
          </b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>