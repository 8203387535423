<script>
import OcppCommandsService from '../../services/api/ocpp/OcppCommandsService'
import StationsService from '../../services/api/ocpp/StationsService'
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

import DataTransferModal from '../../components/modals/ocpp/DataTransferModal'
import RemoteStartTransactionModal from '../../components/modals/ocpp/RemoteStartTransactionModal'
import RemoteStopTransactionModal from '../../components/modals/ocpp/RemoteStopTransactionModal'
import UnlockConnectorModal from '../../components/modals/ocpp/UnlockConnectorModal'
import ChangeAvailabilityModal from '../../components/modals/ocpp/ChangeAvailabilityModal'
import SetChargingScheduleModal from '../../components/modals/ocpp/SetChargingScheduleModal'
import GetChargingScheduleModal from '../../components/modals/ocpp/GetChargingScheduleModal'
import ClearChargingScheduleModal from '../../components/modals/ocpp/ClearChargingScheduleModal'
import TriggerMessageModal from '../../components/modals/ocpp/TriggerMessageModal'
import DeleteStationModal from '../../components/modals/DeleteStationModal'

export default {
  name: 'StationDetailsPage',
  components: {
    DataTransferModal,
    RemoteStartTransactionModal,
    RemoteStopTransactionModal,
    UnlockConnectorModal,
    ChangeAvailabilityModal,
    SetChargingScheduleModal,
    GetChargingScheduleModal,
    ClearChargingScheduleModal,
    TriggerMessageModal,
    DeleteStationModal
  },
  props: [ 'notificationsService' ],
  data: function() {
    return {
      isLoading: true,
      isDataTransferModalVisible: false,
      isRemoteStartTransactionModalVisible: false,
      isRemoteStopTransactionModalVisible: false,
      isUnlockConnectorModalVisible: false,
      isChangeAvailabilityModalVisible: false,
      isSetChargingScheduleModalVisible: false,
      isGetChargingScheduleModalVisible: false,
      isClearChargingScheduleModalVisible: false,
      isTriggerMessageModalVisible: false,
      isDeleteStationModalVisible: false,
      isClearCacheLoading: false,
      changeAvailabilityRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      setChargingScheduleRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      getChargingScheduleRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      clearChargingScheduleRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      triggerMessageRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      clearCacheRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      getConfigurationRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      changeConfigurationRequest: {
        isLoading: {},
        isSuccessful: {}
      },
      unlockConnectorRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      dataTransferRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      remoteStartTransactionRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      remoteStopTransactionRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      softResetRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      hardResetRequest: {
        isLoading: false,
        isInitiated: false,
        isSuccessful: false
      },
      station: null,
      stationModel: null,
      evseStatuses: [],
      notifications: [],
      configuration: [],
      readConfiguration: [],
      userConfiguration: [],
      ocppService: new OcppCommandsService(this),
      stationsService: new StationsService(this),
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    areReservationsEnabled: function(isReservationsEnabled) {
      return isReservationsEnabled 
        && isReservationsEnabled.toLowerCase() === 'enabled'
    },
    showUnlockConnectorModal: function () {
      this.isUnlockConnectorModalVisible = true
    },
    showDataTransferModal: function() {
      this.isDataTransferModalVisible = true
    },
    showRemoteStartTransactionModal: function () {
      this.isRemoteStartTransactionModalVisible = true
    },
    showRemoteStopTransactionModal: function () {
      this.isRemoteStopTransactionModalVisible = true
    },
    showChangeAvailabilityModal: function () {
      this.isChangeAvailabilityModalVisible = true
    },
    showSetChargingScheduleModal: function () {
      this.isSetChargingScheduleModalVisible = true
    },
    showGetChargingScheduleModal: function () {
      this.isGetChargingScheduleModalVisible = true
    },
    showClearChargingScheduleModal: function () {
      this.isClearChargingScheduleModalVisible = true
    },
    showTriggerMessageModal: function () {
      this.isTriggerMessageModalVisible = true
    },
    showDeleteStationModal: function () {
      this.isDeleteStationModalVisible = true
    },
    clearCache: async function (stationID) {
      this.clearCacheRequest.isInitiated = true
      this.clearCacheRequest.isLoading = true

      if(stationID && stationID > 0) {
        const response = await this.ocppService.clearCache(stationID)

        if(!response || !response.initiated) {
          this.toastService.error('Failed to clear cache')
          this.clearCacheRequest.isLoading = false
          setTimeout(() => this.resetClearCacheRequest(), 5000)
        }
      } else {
        this.toastService.error('Station not found')
        this.clearCacheRequest.isLoading = false
        setTimeout(() => this.resetClearCacheRequest(), 5000)
      }
    },
    softReset: async function (stationID) {
      this.softResetRequest.isInitiated = true
      this.softResetRequest.isLoading = true

      if(stationID && stationID > 0) {
        const response = await this.ocppService.softReset(stationID)

        if(!response || !response.initiated) {
          this.toastService.error('Failed to soft reset')
          this.softResetRequest.isLoading = false
          setTimeout(() => this.resetSoftResetRequest(), 5000)
        }
      } else {
        this.toastService.error('Station not found')
        this.softResetRequest.isLoading = false
        setTimeout(() => this.resetSoftResetRequest(), 5000)
      }
    },
    hardReset: async function (stationID) {
      this.hardResetRequest.isInitiated = true
      this.hardResetRequest.isLoading = true

      if(stationID && stationID > 0) {
        const response = await this.ocppService.hardReset(stationID)

        if(!response || !response.initiated) {
          this.toastService.error('Failed to hard reset')
          this.hardResetRequest.isLoading = false
          setTimeout(() => this.resetHardResetRequest(), 5000)
        }
      } else {
        this.toastService.error('Station not found')
        this.hardResetRequest.isLoading = false
        setTimeout(() => this.resetHardResetRequest(), 5000)
      }
    },
    unlockConnector: async function (values) {
      this.unlockConnectorRequest.isInitiated = true
      this.unlockConnectorRequest.isLoading = true

      const response = await this.ocppService.unlockConnector(
        this.station.id, 
        values.connectorId
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to unlock connector')
        this.unlockConnectorRequest.isLoading = false
        setTimeout(() => this.resetUnlockConnectorRequest(), 5000)
      }
    },
    dataTransfer: async function (values) {
      this.dataTransferRequest.isInitiated = true
      this.dataTransferRequest.isLoading = true

        const response = await this.ocppService.dataTransfer(
          this.station.id, 
          values.vendorId,
          values.messageId,
          values.data
        )

        if(!response || !response.initiated) {
          this.toastService.error('Failed to transfer data')
          this.dataTransferRequest.isLoading = false
          setTimeout(() => this.resetDataTransferRequest(), 5000)
        }
    },
    remoteStartTransaction: async function (values) {
      this.remoteStartTransactionRequest.isInitiated = true
      this.remoteStartTransactionRequest.isLoading = true

      const response = await this.ocppService.remoteStartTransaction(
        this.station.id, 
        values.connectorId,
        values.chargingProfileId
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to send remote start transaction request')
        this.remoteStartTransactionRequest.isLoading = false
        setTimeout(() => this.resetRemoteStartTransactionRequest(), 5000)
      }
    },
    remoteStopTransaction: async function(values) {
      this.remoteStopTransactionRequest.isInitiated = true
      this.remoteStopTransactionRequest.isLoading = true

      const response = await this.ocppService.remoteStopTransaction(
        this.station.id, 
        values.connectorId,
        values.transactionId
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to send remote stop transaction request')
        this.remoteStopTransactionRequest.isLoading = false
        setTimeout(() => this.resetRemoteStopTransactionRequest(), 5000)
      }
    },
    changeAvailability: async function (values) {
      this.changeAvailabilityRequest.isInitiated = true
      this.changeAvailabilityRequest.isLoading = true

      const response = await this.ocppService.changeAvailability(
        this.station.id,
        values.connectorId, 
        values.availabilityType
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to change availability')
        this.changeAvailabilityRequest.isLoading = false
        setTimeout(() => this.resetChangeAvailabilityRequest(), 5000)
      }
    },
    setChargingSchedule: async function (values) {
      this.setChargingScheduleRequest.isInitiated = true
      this.setChargingScheduleRequest.isLoading = true

      const response = await this.ocppService.setChargingProfile(
        this.station.id,
        values.connectorId,
        values.chargingProfileId
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to set charging schedule')
        this.setChargingScheduleRequest.isLoading = false
        setTimeout(() => this.resetSetChargingScheduleRequest(), 5000)
      }
    },
    getChargingSchedule: async function (values) {
      this.getChargingScheduleRequest.isInitiated = true
      this.getChargingScheduleRequest.isLoading = true

      const response = await this.ocppService.getChargingProfile(
        this.station.id,
        values.connectorId,
        values.duration,
        values.chargingRateUnit
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to get charging schedule')
        this.getChargingScheduleRequest.isLoading = false
        setTimeout(() => this.resetGetChargingScheduleRequest(), 5000)
      }
    },
    clearChargingSchedule: async function (values) {
      this.clearChargingScheduleRequest.isInitiated = true
      this.clearChargingScheduleRequest.isLoading = true

      const response = await this.ocppService.clearChargingProfile(
        this.station.id,
        values.id,
        values.connectorId,
        values.chargingProfilePurpose,
        values.stackLevel
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to clear charging schedule')
        this.clearChargingScheduleRequest.isLoading = false
        setTimeout(() => this.resetClearChargingScheduleRequest(), 5000)
      }
    },
    triggerMessage: async function (values) {
      this.triggerMessageRequest.isInitiated = true
      this.triggerMessageRequest.isLoading = true

      const response = await this.ocppService.triggerMessage(
        this.station.id,
        values.triggerMessageType, 
        values.connectorId
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to trigger message')
        this.triggerMessageRequest.isLoading = false
        setTimeout(() => this.resetTriggerMessageRequest(), 5000)
      }
    },
    onDeleteStationModalSubmit: async function() {
      const response = await this.stationsService.deleteStation(this.station.id)

      if(response) {
        this.toastService.success('Station successfully deleted')
        this.$router.back()
      } else {
        this.toastService.error('Failed to delete station')
      }
    },
    getStationById: async function() {
      if(this.$route.params.id) {
        this.station = await this.stationsService.getStationById(this.$route.params.id)
      } else {
        this.isLoading = false
        this.toastService.error('Station not found')
        this.$router.back()
      }
    },
    getStationModel: async function() {
      this.stationModel = await this.stationsService.getStationModelByStationId(
        this.$route.params.id
      )
    },
    getEvseStatuses: async function() {
      if(this.station.chargeboxID) {
        this.evseStatuses = await this.trackingService.getEvseStatusForStation(
          this.station.chargeboxID
        )
      }
    },
    getNotifications: async function() {
      this.notifications = await this.notificationsService.getNotifications({
        stationId: this.station.id
      })
    },
    resetClearCacheRequest: function () {
      this.clearCacheRequest.isInitiated = false
      this.clearCacheRequest.isLoading = false
      this.clearCacheRequest.isSuccessful = false
    },
    resetChangeAvailabilityRequest: function() {
      this.changeAvailabilityRequest.isInitiated = false
      this.changeAvailabilityRequest.isLoading = false
      this.changeAvailabilityRequest.isSuccessful = false
    },
    resetSetChargingScheduleRequest: function() {
      this.setChargingScheduleRequest.isInitiated = false
      this.setChargingScheduleRequest.isLoading = false
      this.setChargingScheduleRequest.isSuccessful = false
    },
    resetGetChargingScheduleRequest: function() {
      this.getChargingScheduleRequest.isInitiated = false
      this.getChargingScheduleRequest.isLoading = false
      this.getChargingScheduleRequest.isSuccessful = false
    },
    resetClearChargingScheduleRequest: function() {
      this.clearChargingScheduleRequest.isInitiated = false
      this.clearChargingScheduleRequest.isLoading = false
      this.clearChargingScheduleRequest.isSuccessful = false
    },
    resetTriggerMessageRequest: function() {
      this.triggerMessageRequest.isInitiated = false
      this.triggerMessageRequest.isLoading = false
      this.triggerMessageRequest.isSuccessful = false
    },
    resetUnlockConnectorRequest: function() {
      this.unlockConnectorRequest.isInitiated = false
      this.unlockConnectorRequest.isLoading = false
      this.unlockConnectorRequest.isSuccessful = false
    },
    resetDataTransferRequest: function() {
      this.dataTransferRequest.isInitiated = false
      this.dataTransferRequest.isLoading = false
      this.dataTransferRequest.isSuccessful = false
    },
    resetRemoteStartTransactionRequest: function() {
      this.remoteStartTransactionRequest.isInitiated = false
      this.remoteStartTransactionRequest.isLoading = false
      this.remoteStartTransactionRequest.isSuccessful = false
    },
    resetRemoteStopTransactionRequest: function() {
      this.remoteStopTransactionRequest.isInitiated = false
      this.remoteStopTransactionRequest.isLoading = false
      this.remoteStopTransactionRequest.isSuccessful = false
    },
    resetSoftResetRequest: function() {
      this.softResetRequest.isInitiated = false
      this.softResetRequest.isLoading = false
      this.softResetRequest.isSuccessful = false
    },
    resetHardResetRequest: function() {
      this.hardResetRequest.isInitiated = false
      this.hardResetRequest.isLoading = false
      this.hardResetRequest.isSuccessful = false
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification)
    },
    onIncomingNotification: function (notification) {
      if (notification.stationId !== this.station.id)
        return;        

      this.getNotifications()

      console.log('Notification type: ', notification.type)

      switch(notification.type) {
        case 'CHARGE_POINT_CONNECTED':
          this.getStationById()
          break
        case 'CHARGE_POINT_DISCONNECTED':
          this.getStationById()
          break
        case 'RECEIVED_START_TRANSACTION_REQUEST':
          this.getEvseStatuses()
          break
        case 'RECEIVED_STOP_TRANSACTION_REQUEST':
          this.getEvseStatuses()
          break
        case 'RECEIVED_STATUS_NOTIFICATION_REQUEST':
          this.getEvseStatuses()
          break
        case 'CLEAR_CACHE_SUCCESS':
          this.clearCacheRequest.isLoading = false
          this.clearCacheRequest.isSuccessful = true
          setTimeout(() => this.resetClearCacheRequest(), 5000)
          break
        case 'CLEAR_CACHE_ERROR':
          this.clearCacheRequest.isLoading = false
          this.clearCacheRequest.isSuccessful = false
          setTimeout(() => this.resetClearCacheRequest(), 5000)
          break
        case 'SOFT_RESET_SUCCESS':
          this.softResetRequest.isLoading = false
          this.softResetRequest.isSuccessful = true
          setTimeout(() => this.resetSoftResetRequest(), 5000)
          break
        case 'SOFT_RESET_ERROR':
          this.softResetRequest.isLoading = false
          this.softResetRequest.isSuccessful = false
          setTimeout(() => this.resetSoftResetRequest(), 5000)
          break
        case 'HARD_RESET_SUCCESS':
          this.hardResetRequest.isLoading = false
          this.hardResetRequest.isSuccessful = true
          setTimeout(() => this.resetHardResetRequest(), 5000)
          break
        case 'HARD_RESET_ERROR':
          this.hardResetRequest.isLoading = false
          this.hardResetRequest.isSuccessful = false
          setTimeout(() => this.resetHardResetRequest(), 5000)
          break
        case 'UNLOCK_CONNECTOR_SUCCESS':
          this.unlockConnectorRequest.isLoading = false
          this.unlockConnectorRequest.isSuccessful = true
          setTimeout(() => this.resetUnlockConnectorRequest(), 5000)
          break
        case 'METHOD_NOT_SUPPORTED':
          this.unlockConnectorRequest.isLoading = false
          this.unlockConnectorRequest.isSuccessful = true
          setTimeout(() => this.resetUnlockConnectorRequest(), 5000)
          break
        case 'UNLOCK_CONNECTOR_ERROR':
          this.unlockConnectorRequest.isLoading = false
          this.unlockConnectorRequest.isSuccessful = false
          setTimeout(() => this.resetUnlockConnectorRequest(), 5000)
          break
        case 'DATA_TRANSFER_SUCCESS':
          this.dataTransferRequest.isLoading = false
          this.dataTransferRequest.isSuccessful = true
          setTimeout(() => this.resetDataTransferRequest(), 5000)
          break
        case 'DATA_TRANSFER_ERROR':
          this.dataTransferRequest.isLoading = false
          this.dataTransferRequest.isSuccessful = false
          setTimeout(() => this.resetDataTransferRequest(), 5000)
          break
        case 'ON_ERROR':
          this.dataTransferRequest.isLoading = false
          this.dataTransferRequest.isSuccessful = false
          setTimeout(() => this.resetDataTransferRequest(), 5000)
          break
        case 'START_TRANSACTION_SUCCESS':
          this.remoteStartTransactionRequest.isLoading = false
          this.remoteStartTransactionRequest.isSuccessful = true
          setTimeout(() => this.resetRemoteStartTransactionRequest(), 5000)
          break
        case 'START_TRANSACTION_ERROR':
          this.remoteStartTransactionRequest.isLoading = false
          this.remoteStartTransactionRequest.isSuccessful = false
          setTimeout(() => this.resetRemoteStartTransactionRequest(), 5000)
          break
        case 'STOP_TRANSACTION_SUCCESS':
          this.remoteStopTransactionRequest.isLoading = false
          this.remoteStopTransactionRequest.isSuccessful = true
          setTimeout(() => this.resetRemoteStopTransactionRequest(), 5000)
          break
        case 'STOP_TRANSACTION_ERROR':
          this.remoteStopTransactionRequest.isLoading = false
          this.remoteStopTransactionRequest.isSuccessful = false
          setTimeout(() => this.resetRemoteStopTransactionRequest(), 5000)
          break
        case 'CHANGE_AVAILABILITY_SUCCESS':
          this.changeAvailabilityRequest.isLoading = false
          this.changeAvailabilityRequest.isSuccessful = true
          setTimeout(() => this.resetChangeAvailabilityRequest(), 5000)
          break
        case 'CHANGE_AVAILABILITY_ERROR':
          this.changeAvailabilityRequest.isLoading = false
          this.changeAvailabilityRequest.isSuccessful = false
          setTimeout(() => this.resetChangeAvailabilityRequest(), 5000)
          break
        case 'CHANGE_AVAILABILITY_SCHEDULED':
          this.changeAvailabilityRequest.isLoading = false
          this.changeAvailabilityRequest.isSuccessful = true
          setTimeout(() => this.resetChangeAvailabilityRequest(), 5000)
          break
        case 'SET_CHARGING_PROFILE_SUCCESS':
          this.setChargingScheduleRequest.isLoading = false
          this.setChargingScheduleRequest.isSuccessful = true
          setTimeout(() => this.resetSetChargingScheduleRequest(), 5000)
          break
        case 'SET_CHARGING_PROFILE_ERROR':
          this.setChargingScheduleRequest.isLoading = false
          this.setChargingScheduleRequest.isSuccessful = false
          setTimeout(() => this.resetSetChargingScheduleRequest(), 5000)
          break
        case 'GET_COMPOSITE_SCHEDULE_SUCCESS':
          this.getChargingScheduleRequest.isLoading = false
          this.getChargingScheduleRequest.isSuccessful = true
          setTimeout(() => this.resetGetChargingScheduleRequest(), 5000)
          break
        case 'GET_COMPOSITE_SCHEDULE_ERROR':
          this.getChargingScheduleRequest.isLoading = false
          this.getChargingScheduleRequest.isSuccessful = false
          setTimeout(() => this.resetGetChargingScheduleRequest(), 5000)
          break
        case 'CLEAR_CHARGING_PROFILE_SUCCESS':
          this.clearChargingScheduleRequest.isLoading = false
          this.clearChargingScheduleRequest.isSuccessful = true
          setTimeout(() => this.resetClearChargingScheduleRequest(), 5000)
          break
        case 'CLEAR_CHARGING_PROFILE_ERROR':
          this.clearChargingScheduleRequest.isLoading = false
          this.clearChargingScheduleRequest.isSuccessful = false
          setTimeout(() => this.resetClearChargingScheduleRequest(), 5000)
          break
        case 'TRIGGER_MESSAGE_SUCCESS':
          this.triggerMessageRequest.isLoading = false
          this.triggerMessageRequest.isSuccessful = true
          setTimeout(() => this.resetTriggerMessageRequest(), 5000)
          break
        case 'TRIGGER_MESSAGE_ERROR':
          this.triggerMessageRequest.isLoading = false
          this.triggerMessageRequest.isSuccessful = false
          setTimeout(() => this.resetTriggerMessageRequest(), 5000)
          break
        case 'CHANGE_CONFIGURATION_SUCCESS':
        case 'CHANGE_CONFIGURATION_ERROR':
          this.handleChangeConfiguration(notification)
          break;
        case 'GET_CONFIGURATION_SUCCESS':
        case 'GET_CONFIGURATION_ERROR':
          this.handleGetConfiguration(notification)
          break
      }
    },
    getConfiguration: async function () {
      this.getConfigurationRequest.isInitiated = true
      this.getConfigurationRequest.isLoading = true

      if(this.station.id && this.station.id > 0) {
        const response = await this.ocppService.getConfiguration(this.station.id)

        if(!response || !response.initiated) {
          this.toastService.error('Failed to get configuration')
          this.getConfigurationRequest.isLoading = false
        }
      } else {
        this.toastService.error('Station not found')
        this.getConfigurationRequest.isLoading = false
      }
    },
    handleGetConfiguration: function (notification) {
      this.getConfigurationRequest.isLoading = false
      if (notification.type === 'GET_CONFIGURATION_SUCCESS') {
        this.getConfigurationRequest.isSuccessful = true
        let configuration = JSON.parse(notification.payload).configurationKey
        configuration.forEach(kv_pair => {
          if (!kv_pair.readonly) {
            if (!this.configuration.map(c => c.key).includes(kv_pair.key)) {
              this.changeConfigurationRequest.isLoading[kv_pair.key] = false
              this.changeConfigurationRequest.isSuccessful[kv_pair.key] = ''
              this.configuration.push(kv_pair)
            }
          } else {
            if (!this.readConfiguration.map(c => c.key).includes(kv_pair.key)) {
              this.readConfiguration.push(kv_pair)
            }
          }
        })
      } else {
        this.getConfigurationRequest.isSuccessful = false
      }
    },
    changeConfiguration: async function (kv_pair) {
      if (kv_pair.key.trim() === '') {
        return
      }

      this.changeConfigurationRequest.isLoading[kv_pair.key] = true
      this.changeConfigurationRequest.isLoading[kv_pair.id] = true

      const response = await this.ocppService.changeConfiguration(
        this.station.id, 
        kv_pair.key, 
        kv_pair.value
      )

      if(!response || !response.initiated) {
        this.toastService.error('Failed to change configuration')
        this.changeConfigurationRequest.isLoading[kv_pair.key] = false
        this.changeConfigurationRequest.isLoading[kv_pair.id] = false
        this.changeConfigurationRequest.isSuccessful[kv_pair.key] = 'Error'
        this.changeConfigurationRequest.isSuccessful[kv_pair.id] = 'Error'
      }
    },
    handleChangeConfiguration: function (notification) {
      let response = JSON.parse(notification.payload)
      let user_kvs = this.userConfiguration.filter(c => c.key === response.key)
      this.changeConfigurationRequest.isLoading[response.key] = false
      user_kvs.forEach(user_kv => {
        this.changeConfigurationRequest.isLoading[user_kv.id] = false
      })
      if (notification.type === 'CHANGE_CONFIGURATION_SUCCESS') {
        this.changeConfigurationRequest.isSuccessful[response.key] = response.requestConfirmation.status
        user_kvs.forEach(user_kv => {
          this.changeConfigurationRequest.isSuccessful[user_kv.id] = response.requestConfirmation.status
        })
      } else {
        if (response.requestConfirmation) {
          this.changeConfigurationRequest.isSuccessful[response.key] = response.requestConfirmation.status
          user_kvs.forEach(user_kv => {
            this.changeConfigurationRequest.isSuccessful[user_kv.id] = response.requestConfirmation.status
          })
        } else {
          this.changeConfigurationRequest.isSuccessful[response.key] = 'Error'
          user_kvs.forEach(user_kv => {
            this.changeConfigurationRequest.isSuccessful[user_kv.id] = 'Error'
          })
        }
      }
    },
    addNewUserConfiguration: function() {
      let configuration = {
        id: Math.max(0, ...this.userConfiguration.map(c => c.id).filter(id => id)) + 1,
        key: '',
        value: ''
      }
      this.changeConfigurationRequest.isLoading[configuration.id] = false
      this.changeConfigurationRequest.isSuccessful[configuration.id] = ''
      this.userConfiguration.push(configuration)
    }
  },
  async mounted() {
    this.isLoading = true

    await this.connectToChannel()
    await this.getStationById()

    if(!this.station) {
      this.isLoading = false
      this.toastService.error('Station not found')
      this.$router.back()
    } else {
      await this.getStationModel()
      await this.getEvseStatuses()
      await this.getNotifications()
      this.isLoading = false
    }
  }
}
</script>

<template>
<div class="station-details page">
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div class="station-details-page-container" v-else>
    <div v-if="!station" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p><strong>Station</strong> not found</p>
    </div>
    <div v-else class="container-fluid">
      <div class="row page-header-container">
        <div class="page-header" v-if="this.station">
          <h1>{{ this.station.name }}</h1>
          <div class="main-commands">
            <router-link
              :to="`/stations/${this.station.id}/edit`" 
              class="btn btn-success text-success main-command-btn">
              <img src="../../assets/actions/edit.svg">
              Edit
            </router-link>
            <button 
              @click="showDeleteStationModal()" 
              class="btn btn-danger text-danger main-command-btn">
              <img src="../../assets/actions/delete.svg">
              Delete
            </button>
          </div>
        </div>
      </div>

      <div class="row tabs-container">
        <b-tabs class="g-0">
          <b-tab :active="$route.hash === '#' || $route.hash === '' || $route.hash === 'info'">
            <template v-slot:title>
              <router-link to="#info">
                <span class="tab-title">
                  <!-- <b-spinner type="border" small></b-spinner> -->
                  <strong>Info</strong>
                </span>
              </router-link>
            </template>
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="card info">
                    <div class="card-header">
                      <h3>Station info</h3>
                    </div>
                    <div class="card-body">
                      <ul class="card-list">
                        <li>
                          <span class="title">ID</span>
                          <span class="value">{{ this.station.id || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Organization</span>
                          <span class="value">{{ this.station.organization || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Group ID</span>
                          <span class="value">{{ this.station.groupID || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Name</span>
                          <span class="value">{{ this.station.name || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Chargebox ID</span>
                          <span class="value">{{ this.station.chargeboxID || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Address</span>
                          <a class="value"
                            target="_blank"
                            :href="`https://maps.google.com/?q=${this.station.latitude},${this.station.longitude}`">
                            <span>{{ this.station.address || '/' }}</span>
                          </a>
                        </li>
                        <li>
                          <span class="title">Description</span>
                          <span class="value">{{ this.station.description || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Are reservations enabled?</span>
                          <span class="value">
                            <img v-if="areReservationsEnabled(this.station.isReservationsEnabled)" 
                              src="../../assets/checked.svg" alt="Reservations Enabled">
                            <img v-else src="../../assets/unchecked.svg" alt="Reservations Disabled">
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="card software-details">
                    <div class="card-header">
                      <h3>Software details</h3>
                    </div>
                    <div class="card-body">
                      <ul class="card-list">
                        <li>
                          <span class="title">Software version</span>
                          <span class="value">{{ this.station.softwareVersion || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">OCPP version</span>
                          <span class="value">{{ this.station.ocppVersion || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Serial number</span>
                          <span class="value">{{ this.station.serialNumber || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Meter serial number</span>
                          <span class="value">{{ this.station.meterSerialNumber || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Meter type</span>
                          <span class="value">{{ this.station.meterType || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">MAC adress</span>
                          <span class="value">{{ this.station.macAddress || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">ICCID</span>
                          <span class="value">{{ this.station.iccid || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">IMSI</span>
                          <span class="value">{{ this.station.imsi || '/' }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="card activation">
                    <div class="card-header">
                      <h3>Activation</h3>
                    </div>
                    <div class="card-body">
                      <ul class="card-list">
                        <li>
                          <span class="title">Activation status</span>
                          <span class="value">
                            <b-badge v-if="station.activationStatus" 
                              :class="'pill activation-status-' + station.activationStatus.toLowerCase().replace('_','-')">
                              {{ station.activationStatus.toUpperCase() }}
                            </b-badge>
                          </span>
                        </li>
                        <li v-if="this.station.activationStatus !== 'Inactive'">
                          <span class="title">Activation date</span>
                          <span class="value">{{ $moment(this.station.activationDate).format("MMMM Do YYYY") }}</span>
                        </li>
                        <li>
                          <span class="title">Network status</span>
                          <span class="value">
                            <b-badge v-if="station.networkStatus" 
                              :class="'pill network-status-' + station.networkStatus.toLowerCase().replace('_','-')">
                              {{ station.networkStatus.toUpperCase() }}
                            </b-badge>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="card station-model">
                    <div class="card-header">
                      <h3>Station model</h3>
                    </div>
                    <div class="card-body">
                      <ul v-if="this.stationModel" class="card-list">
                        <li>
                          <span class="title">Manufacturer</span>
                          <span class="value">{{ this.stationModel.manufacturer || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Model</span>
                          <span class="value">{{ this.stationModel.model || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Evse Type</span>
                          <span class="value">{{ this.stationModel.evseType || '/' }}</span>
                        </li>
                        <li>
                          <span class="title">Max Amperage</span>
                          <span class="value">{{ this.stationModel.maxAmperage || '/' }}</span>
                        </li>
                      </ul>
                      <div v-else class="no-data-found">
                        <p>No <strong>model</strong> related to this station is available</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab :active="$route.hash === '#commands'">
            <template v-slot:title>
              <router-link to="#commands">
                <span class="tab-title">
                  <strong>Commands</strong>
                </span>
              </router-link>
            </template>
            <div class="commands container-fluid">
              <div class="row">
                <div class="command col-md-6 col-sm-12">
                  <button @click="showUnlockConnectorModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/unlock_connector.svg" alt="Unlock connector">
                      <span>Unlock connector</span>
                    </span>
                    <span class="spinner" v-if="unlockConnectorRequest.isInitiated">
                      <b-spinner v-if="unlockConnectorRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!unlockConnectorRequest.isLoading && unlockConnectorRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!unlockConnectorRequest.isLoading && !unlockConnectorRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showChangeAvailabilityModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/change_availability.svg" alt="Change availability">
                      <span>Change availability</span>
                    </span>
                    <span class="spinner" v-if="changeAvailabilityRequest.isInitiated">
                      <b-spinner v-if="changeAvailabilityRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!changeAvailabilityRequest.isLoading && changeAvailabilityRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!changeAvailabilityRequest.isLoading && !changeAvailabilityRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showRemoteStartTransactionModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/remote_start_transaction.svg" alt="Remote start transaction">
                      <span>Remote start transaction</span>
                    </span>
                    <span class="spinner" v-if="remoteStartTransactionRequest.isInitiated">
                      <b-spinner v-if="remoteStartTransactionRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!remoteStartTransactionRequest.isLoading && remoteStartTransactionRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!remoteStartTransactionRequest.isLoading && !remoteStartTransactionRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showRemoteStopTransactionModal()" 
                    class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/remote_stop_transaction.svg" alt="Remote stop transaction">
                      <span>Remote stop transaction</span>
                    </span>
                    <span class="spinner" v-if="remoteStopTransactionRequest.isInitiated">
                      <b-spinner v-if="remoteStopTransactionRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!remoteStopTransactionRequest.isLoading && remoteStopTransactionRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!remoteStopTransactionRequest.isLoading && !remoteStopTransactionRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showSetChargingScheduleModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/navigation/month-average.svg" alt="Set Charging Profile">
                      <span>Set Charging Profile</span>
                    </span>
                    <span class="spinner" v-if="setChargingScheduleRequest.isInitiated">
                      <b-spinner v-if="setChargingScheduleRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!setChargingScheduleRequest.isLoading && setChargingScheduleRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!setChargingScheduleRequest.isLoading && !setChargingScheduleRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showGetChargingScheduleModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/navigation/month-average.svg" alt="Get Composite Charging Profile">
                      <span>Get Composite Charging Profile</span>
                    </span>
                    <span class="spinner" v-if="getChargingScheduleRequest.isInitiated">
                      <b-spinner v-if="getChargingScheduleRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!getChargingScheduleRequest.isLoading && getChargingScheduleRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!getChargingScheduleRequest.isLoading && !getChargingScheduleRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showClearChargingScheduleModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/navigation/month-average.svg" alt="Clear Charging Profile">
                      <span>Clear Charging Profile</span>
                    </span>
                    <span class="spinner" v-if="clearChargingScheduleRequest.isInitiated">
                      <b-spinner v-if="clearChargingScheduleRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!clearChargingScheduleRequest.isLoading && clearChargingScheduleRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!clearChargingScheduleRequest.isLoading && !clearChargingScheduleRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showTriggerMessageModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/change_configuration.svg" alt="Trigger Message">
                      <span>Trigger Message</span>
                    </span>
                    <span class="spinner" v-if="triggerMessageRequest.isInitiated">
                      <b-spinner v-if="triggerMessageRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!triggerMessageRequest.isLoading && triggerMessageRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!triggerMessageRequest.isLoading && !triggerMessageRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="showDataTransferModal()" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/data_transfer.svg" alt="Data transfer">
                      <span>Data transfer</span>
                    </span>
                    <span class="spinner" v-if="dataTransferRequest.isInitiated">
                      <b-spinner v-if="dataTransferRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!dataTransferRequest.isLoading && dataTransferRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!dataTransferRequest.isLoading && !dataTransferRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="clearCache(station.id)" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/clear_cache.svg" alt="Clear cache">
                      <span>Clear cache</span>
                    </span>
                    <span class="spinner" v-if="clearCacheRequest.isInitiated">
                      <b-spinner v-if="clearCacheRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!clearCacheRequest.isLoading && clearCacheRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!clearCacheRequest.isLoading && !clearCacheRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="softReset(station.id)" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/soft_reset.svg" alt="Soft reset">
                      <span>Soft reset</span>
                    </span>
                    <span class="spinner" v-if="softResetRequest.isInitiated">
                      <b-spinner v-if="softResetRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!softResetRequest.isLoading && softResetRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!softResetRequest.isLoading && !softResetRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
                <div class="command col-md-6 col-sm-12">
                  <button @click="hardReset(station.id)" class="btn btn-info">
                    <span class="title">
                      <img src="../../assets/commands/hard_reset.svg" alt="Hard reset">
                      <span>Hard reset</span>
                    </span>
                    <span class="spinner" v-if="hardResetRequest.isInitiated">
                      <b-spinner v-if="hardResetRequest.isLoading" type="border" small></b-spinner>
                      <img 
                        v-if="!hardResetRequest.isLoading && hardResetRequest.isSuccessful" 
                        src="../../assets/checked.svg">
                      <img 
                        v-if="!hardResetRequest.isLoading && !hardResetRequest.isSuccessful" 
                        src="../../assets/unchecked.svg">
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab :active="$route.hash === '#configuration'">
            <template v-slot:title>
              <router-link to="#configuration">
                <span class="tab-title">
                  <strong>Configuration</strong>
                </span>
              </router-link>
            </template>
            <div v-if="!getConfigurationRequest.isSuccessful" class="no-data-found">
              <button @click="getConfiguration()" class="btn btn-info" id="btn-configuration">
                <span class="title">
                  <span>Get configuration</span>
                </span>
              </button>
              <p v-if="getConfigurationRequest.isInitiated && !getConfigurationRequest.isLoading">Failed to get <strong>configuration</strong></p>
              <b-spinner v-if="getConfigurationRequest.isInitiated && getConfigurationRequest.isLoading" type="border" small></b-spinner>
            </div>
            <div v-else-if="(!configuration || !configuration.length) && (!readConfiguration || !readConfiguration.length)" class="no-data-found">
              <img src="../../assets/actions/no_data.svg">
              <p>No <strong>configuration</strong> available</p>
            </div>
            <table v-else class="configuration-table">
              <thead>
                <th style="width: 30%!important;">Key</th>
                <th class="left" style="padding-left: 50px">Value</th>
                <th class="right">Send</th>
              </thead>
              <tbody>
                <tr v-for="kv_pair of configuration" 
                  :key="kv_pair.key">
                  <td>{{ kv_pair.key }}</td>
                  <td class="center">
                    <input
                      class="form-control"
                      v-model="kv_pair.value"
                      v-on:input="changeConfigurationRequest.isSuccessful[kv_pair.key] = ''"
                      :placeholder="kv_pair.value">
                  </td>
                  <td class="right">
                    <b-spinner v-if="changeConfigurationRequest.isLoading[kv_pair.key]" type="border" small></b-spinner>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.key] == 'Error'" class="show-info">
                      <img src="../../assets/unchecked.svg">
                      <span>Error</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.key] == 'Accepted'" class="show-info">
                      <img src="../../assets/checked.svg">
                      <span>Accepted</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.key] == 'Rejected'" class="show-info">
                      <img src="../../assets/unchecked.svg">
                      <span>Rejected</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.key] == 'RebootRequired'" class="show-info">
                      <img src="../../assets/actions/info.svg">
                      <span>Reboot Required</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.key] == 'NotSupported'" class="show-info">
                      <img src="../../assets/actions/no_data.svg">
                      <span>Not Supported</span>
                    </div>
                    <button v-else @click="changeConfiguration(kv_pair)" class="btn btn-info show-info">
                      <img src="../../assets/actions/more.svg">
                      <span>Send</span>
                    </button>
                  </td>
                </tr>
                <tr v-for="kv_pair of userConfiguration" 
                  :key="kv_pair.id">
                  <td>
                    <input
                      class="form-control"
                      v-model="kv_pair.key"
                      v-on:input="changeConfigurationRequest.isSuccessful[kv_pair.id] = ''"
                      placeholder="Required">
                  </td>
                  <td class="center">
                    <input
                      class="form-control"
                      v-model="kv_pair.value"
                      v-on:input="changeConfigurationRequest.isSuccessful[kv_pair.id] = ''">
                  </td>
                  <td class="right">
                    <b-spinner v-if="changeConfigurationRequest.isLoading[kv_pair.id]" type="border" small></b-spinner>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.id] == 'Error'" class="show-info">
                      <img src="../../assets/unchecked.svg">
                      <span>Error</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.id] == 'Accepted'" class="show-info">
                      <img src="../../assets/checked.svg">
                      <span>Accepted</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.id] == 'Rejected'" class="show-info">
                      <img src="../../assets/unchecked.svg">
                      <span>Rejected</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.id] == 'RebootRequired'" class="show-info">
                      <img src="../../assets/actions/info.svg">
                      <span>Reboot Required</span>
                    </div>
                    <div v-else-if="changeConfigurationRequest.isSuccessful[kv_pair.id] == 'NotSupported'" class="show-info">
                      <img src="../../assets/actions/no_data.svg">
                      <span>Not Supported</span>
                    </div>
                    <button v-else @click="changeConfiguration(kv_pair)" class="btn btn-info show-info" type="submit">
                      <img src="../../assets/actions/more.svg">
                      <span>Send</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="center"></td>
                  <td class="right">
                    <button class="btn btn-warning show-info" @click="addNewUserConfiguration()">
                      <img src="../../assets/actions/add.svg">
                      <span>Add New Key</span>
                    </button>
                  </td>
                </tr>
                <tr v-for="kv_pair of readConfiguration" 
                  :key="kv_pair.key">
                  <td>{{ kv_pair.key }}</td>
                  <td class="left">{{ kv_pair.value }}</td>
                  <td class="right">
                    <div class="show-info">
                      <img src="../../assets/actions/no_data.svg">
                      <span>Read Only</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>
          
          <b-tab :active="$route.hash === '#connectors'">
            <template v-slot:title>
              <router-link to="#connectors">
                <span class="tab-title">
                  <!-- <b-spinner type="border" small></b-spinner> -->
                  <strong>Connectors</strong>
                </span>
              </router-link>
            </template>
            <div v-if="!evseStatuses || !evseStatuses.length" class="no-data-found">
              <img src="../../assets/actions/no_data.svg">
              <p>No <strong>connectors</strong> found</p>
            </div>
            <table v-else class="evse-status-table">
              <thead>
                <th>Created At</th>
                <th>Name</th>
                <th>Connector ID</th>
                <th>Transaction ID</th>
                <th>Meter Value</th>
                <th>Status</th>
                <th>Error Code</th>
                <th>Info</th>
              </thead>
              <tbody>
                <tr v-for="evse of evseStatuses" 
                  :key="evse.chargeboxID + evse.connectorID + evse.transactionID">
                  <td>{{ $moment(evse.time).format("MM/DD/YYYY HH:mm:ss") }}</td>
                  <td>{{ evse.name }}</td>
                  <td>{{ evse.connectorID }}</td>
                  <td>{{ evse.transactionID }}</td>
                  <td>{{ evse.meterValue }}</td>
                  <td>
                    <b-badge v-if="evse && evse.status" 
                      :class="'pill station-status-' + evse.status.toLowerCase().replace('_','-')">
                      {{ evse.status.toUpperCase().replace('_',' ') }}
                    </b-badge>
                  </td>
                  <td>{{ evse.errorCode }}</td>
                  <td>
                    <router-link 
                      :to="`/evse-status/${evse.chargeboxID}/${evse.connectorID}`"
                      class="text-info">
                      <img class="action-btn" src="../../assets/actions/info.svg">
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>

          <b-tab :active="$route.hash === '#notifications'">
            <template v-slot:title>
              <router-link to="#notifications">
                <span class="tab-title">
                  <!-- <b-spinner type="border" small></b-spinner> -->
                  <strong>Notifications</strong>
                </span>
              </router-link>
            </template>
            <div v-if="!notifications || !notifications.length" class="no-data-found">
              <img src="../../assets/actions/no_data.svg">
              <p>No <strong>notifications</strong> not found</p>
            </div>
            <table v-else class="notifications-table">
              <thead>
                <th>Created At</th>
                <th class="center">Status</th>
                <th class="left">Title</th>
                <th>Info</th>
              </thead>
              <tbody>
                <tr v-for="notification of notifications" 
                  :key="notification.id">
                  <td>{{ $moment(notification.date).format("HH:mm:ss, MMMM Do YYYY") }}</td>
                  <td class="center">
                    <b-badge :class="notification.status.toLowerCase()">
                    {{ notification.status.toUpperCase().replace('_',' ') }}
                    </b-badge>
                  </td>
                  <td class="left">{{ notification.title }}</td>
                  <td>
                    <router-link :to="'/notifications/' + notification.id"
                    class="text-info">
                      <img class="action-btn" src="../../assets/actions/info.svg">
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
      </div>

    </div>

    <!-- Modals -->
    <data-transfer-modal
      :visible="this.isDataTransferModalVisible"
      @closed="() => { this.isDataTransferModalVisible = false }"
      @submit="dataTransfer">
    </data-transfer-modal>
    <remote-start-transaction-modal
      :visible="this.isRemoteStartTransactionModalVisible"
      @closed="() => { this.isRemoteStartTransactionModalVisible = false }"
      @submit="remoteStartTransaction">
    </remote-start-transaction-modal>
    <remote-stop-transaction-modal
      :visible="this.isRemoteStopTransactionModalVisible"
      @closed="() => { this.isRemoteStopTransactionModalVisible = false }"
      @submit="remoteStopTransaction">
    </remote-stop-transaction-modal>
    <unlock-connector-modal
      :visible="this.isUnlockConnectorModalVisible"
      @closed="() => { this.isUnlockConnectorModalVisible = false }"
      @submit="unlockConnector">
    </unlock-connector-modal>
    <change-availability-modal
      :visible="this.isChangeAvailabilityModalVisible"
      @closed="() => { this.isChangeAvailabilityModalVisible = false }"
      @submit="changeAvailability">
    </change-availability-modal>
    <set-charging-schedule-modal
      :visible="this.isSetChargingScheduleModalVisible"
      @closed="() => { this.isSetChargingScheduleModalVisible = false }"
      @submit="setChargingSchedule">
    </set-charging-schedule-modal>
    <get-charging-schedule-modal
      :visible="this.isGetChargingScheduleModalVisible"
      @closed="() => { this.isGetChargingScheduleModalVisible = false }"
      @submit="getChargingSchedule">
    </get-charging-schedule-modal>
    <clear-charging-schedule-modal
      :visible="this.isClearChargingScheduleModalVisible"
      @closed="() => { this.isClearChargingScheduleModalVisible = false }"
      @submit="clearChargingSchedule">
    </clear-charging-schedule-modal>
    <trigger-message-modal
      :visible="this.isTriggerMessageModalVisible"
      @closed="() => { this.isTriggerMessageModalVisible = false }"
      @submit="triggerMessage">
    </trigger-message-modal>
    <delete-station-modal
      :visible="this.isDeleteStationModalVisible"
      @closed="() => { this.isDeleteStationModalVisible = false }"
      @submit="onDeleteStationModalSubmit"
      :station="station">
    </delete-station-modal>
  </div>
</div>
</template>

<style lang="scss">
.page.station-details {
    overflow: auto;

  .row.page-header-container {
    z-index: 500;
  }
}

.commands {
  justify-content: center;
  margin: 12px 0px;

  .command {
    margin: 12px 0px;
    transition: all 0.3s ease-in-out;

    &:nth-child(odd) {
      padding-left: 20px;
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
      padding-right: 20px;
    }

    .btn {
      background-color: rgb(30, 38, 47);
      width: 100%;
      height: 100%;
      padding: 4px 0px;
      color: #fff;
      border: 0px!important;
      border-radius: 0px!important;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      outline: none!important;
      box-shadow: 0px!important;
      --webkit-box-shadow: none!important;

      .title {
        padding: 12px 4px;
        font-size: 16px;
        text-align: center;

        img {
          padding: 0px;
          height: 20px;
          width: 20px;
          margin: 0px 12px;
          margin-left: 16px;
        }
      }

      .spinner {
        padding: 12px 16px;
        margin: 0px;
        display: flex;
        align-items: center;
      }
    }
  }
}

#btn-configuration {
  display: block;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 32px;
  color: #fff!important;
  margin-bottom: 20px;
}

.show-info {
  position: relative;
  display: inline-block;
}

.show-info > span {
  visibility: hidden;
  background-color: black;
  color: #fff;
  padding: 5px 10px;
  display: block;  
  border-radius: 6px;
  position: absolute;
  top: -5px;
  bottom: auto;
  right: 128%;
  width: 150px;
}

.show-info:hover > span {
  visibility: visible;
}

.no-data-found {
  margin-top: 396px;
}

.station-details-page-container {
  height: 100%;

  .container-fluid {
    height: 100%;
    overflow: auto;
  }
}

.tabs-container {
  padding: 0px!important;
  min-height: 100%;
  overflow: hidden;
}

.tabs {
  width: 100%;
  min-height: 100%;
  overflow: auto;

  .tab-content {
    overflow: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 80px!important;

    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }

    & > .tab-pane {
      height: 100%;
    }
  }

  .nav-tabs {
    padding: 0px 20px;
    background-color: #1d395d!important;
    color: #fff!important;
    z-index: 1000;
    box-shadow: 12px 0px 4px rgba(0, 0, 0, 0.4);
    border-bottom: 0px!important;
    
    .nav-item {
      background-color: transparent!important;

      a {
        text-decoration: none!important;
      }

      .nav-link {
        background-color: transparent!important;
        border: 0px!important;
        padding: 6px 8px;
        transition: all 0.3s ease-in-out!important;

        &.active {
          .tab-title {
            strong {
              border-bottom: 4px solid rgb(255, 255, 255) !important;
              color: rgb(255, 255, 255) !important;
              opacity: 1;
            }
          }
        }

        .tab-title {
          padding: 4px;
          padding-top: 8px!important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff!important;
          transition: all 0.3s ease-in-out !important;
          border-bottom: 4px solid rgb(255, 255, 255, 0) !important;
          margin: 0px 12px!important;

          strong {
            font-size: 14px;
            font-weight: 800;
            text-transform: uppercase;
            padding: 4px 0px;
            opacity: 0.6;
          }
        }
      }

      &:first-child {
        .tab-title {
          margin: 0px!important
        }
      }
    }
  }
}

.tab-pane.active {
    animation: slide-left 0.5s ease-in-out;
}

@keyframes slide-left {
    0% { opacity: 0; }
    100% { opacity: 1;}
}
</style>
