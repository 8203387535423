<script>
import LoginService from '../../services/api/ocpp/LoginService'
import ToastService from '../../services/ToastService'

export default {
  name: 'LoginPage',
  data: function() {
    return {
      isLoading: false,
      loginService: new LoginService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    login: async function(event) {
      event.preventDefault()
      const username = event.target.elements.username.value
      const password = event.target.elements.password.value

      const response = await this.loginService.login(username, password)

      const loginToken = response ? response.token : null

      if(loginToken) {
        this.toastService.success('Login successful, welcome back!')
        await localStorage.setItem('user', username)
        await localStorage.setItem('usertoken', loginToken)
        const redirectUrl = this.$route.params.r || this.$route.query.r
        setTimeout(() => {
          if(redirectUrl) {
          this.$router.push(redirectUrl)
          } else {
          this.$router.push('/stations')
          }
        }, 2000)
      } else {
        this.toastService.error('Login attempt failed, please try again!')
      }
    }
  }
}
</script>

<template>
  <div class="login page">
    <div class="login-container">
      <img src="../../assets/argonne-logo.png">
      <div class="login-form-container">
        <form @submit.prevent="login">
          <div class="form-field">
            <label for="username">Username</label>
            <input 
              type="text" 
              class="form-control" 
              name="username" 
              placeholder="Your username or email">
          </div>

          <div class="form-field">
            <label for="password">Password</label>
            <input 
              type="password" 
              class="form-control" 
              name="password" 
              placeholder="Your password">
          </div>
          <div class="logo-container">
            <input 
              type="submit" 
              class="btn btn-success" 
              name="submit" 
              value="Sign in">
            <div class="app-info">
              <h2 class="logo">OCPP CSMS UI</h2>
              <h4 class="logo-subtitle">
                <span class="app-name">Dashboard</span>
                <span class="logo-version">v1.4.0</span>
              </h4>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.page-container.login {
  height: 100vh;
  top: 0px!important;

  img {
    margin: 0 auto;
    width: 60%;
  }

  .login.page {
    background-color: rgb(30, 38, 47);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .logo-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      padding: 8px 16px;
      border-top: 1px solid rgba(245, 245, 245, 0.2);
      padding-top: 10px;
      margin-top: 24px;

      .logo {
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        margin: 0px auto;
        padding: 0px;
        width: 100%;
        margin-bottom: -8px;
      }

      .logo-subtitle {
        margin: 0px auto;
        padding: 0px;
        width: 100%;
        text-align: right
      }

      .app-name {
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        margin-right: 8px;
        opacity: 0.8;
      }

      .logo-version {
        font-size: 12px;
        font-weight: 800;
        color: #97c4ff;
      }
    }

    .login-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      width: 45%;

      .login-form-container {
        width: 100%;
        padding: 50px;
        
        .form-field {
          margin: 12px 0px;
        }
        
        input:active, input:hover, input:focus {
          outline: none;
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        label {    
          color: #fff;
          width: 100%;
          margin: 0px;
          padding: 12px;
          font-size: 14px;
          font-weight: 800;

          label:first-child {
            margin-top: 0px!important;
          }
        }

        input {
          border: 0px;
          border-radius: 0px;
          background-color: transparent!important;
          color: #fff;
          margin: 0px;
          opacity: 0.7;
        }

        .btn {
          text-transform: uppercase;
          transition: all 0.5s ease;
          border: 0px!important;
          color: rgb(59, 143, 251);
          margin: 0px 0px 0px 0px;
          padding: 8px 0px!important;
          background-color: transparent;
          font-weight: 800;
          text-align: right;

          &:hover {
            padding-left: 18px!important;
          }
        }
      }
    }
  }
}
</style>
