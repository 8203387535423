<script>
import StationsService from '../../services/api/ocpp/StationsService'
import ToastService from '../../services/ToastService'

import CancelStationEditModal from '../../components/modals/CancelStationEditModal'

import moment from 'moment'

export default {
  name: 'ChargingProfileEditPage',
  components: {
    CancelStationEditModal
  },
  data: function() {
    return {
      isLoading: true,
      isCancelStationEditModalVisible: false,
      profile: {},
      chargingProfile: {},
      chargingSchedule: {},
      stationsService: new StationsService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    goBack: function () {
      this.$router.push('/charging-profiles')
    },
    showCancelStationEditModal: function () {
      this.isCancelStationEditModalVisible = true;
    },
    closeCancelStationEditModal: function() {
      this.isCancelStationEditModalVisible = false;
    },
    onCancelStationEditModalSubmit: function() {
      this.goBack()
    },
    getProfileById: async function() {
      this.isLoading = true;

      if(this.$route.params.id) {
        this.profile = await this.stationsService.getSmartChargingProfileById(this.$route.params.id)
        
        if(!this.profile) {
          this.toastService.error('Charging Profile not found')
        } else {
          this.chargingProfile = JSON.parse(this.profile.chargingProfile)
          this.chargingSchedule = this.chargingProfile.chargingSchedule
        }
      }

      this.isLoading = false;
    },
    dateToJSON: function(date) {
      if (date) return moment(date).format()
      else return null
    },
    dateToHTML: function(date) {
      if (date) return moment(date).format('YYYY-MM-DDTHH:mm')
      else return null
    },
    saveProfile: async function(event) {
      const formValues = event.target.elements

      var chargingSchedulePeriod = ''
      try {
        chargingSchedulePeriod = JSON.parse(formValues.chargingSchedulePeriod.value)
      } catch {
        this.toastService.error('Invalid Format for Charging Schedule Period')
        return
      }

      this.chargingSchedule = Object.assign({}, this.chargingSchedule, {
        duration: formValues.duration.value,
        startSchedule: this.dateToJSON(formValues.startSchedule.value),
        chargingRateUnit: formValues.chargingRateUnit.value,
        minChargingRate: formValues.minChargingRate.value,
        chargingSchedulePeriod: chargingSchedulePeriod,
      })

      this.chargingProfile = Object.assign({}, this.chargingProfile, {
        chargingProfileId: formValues.chargingProfileId.value,
        transactionId: formValues.transactionId.value,
        stackLevel: formValues.stackLevel.value,
        chargingProfilePurpose: formValues.chargingProfilePurpose.value,
        chargingProfileKind: formValues.chargingProfileKind.value,
        recurrencyKind: formValues.recurrencyKind.value || null,
        validFrom: this.dateToJSON(formValues.validFrom.value),
        validTo: this.dateToJSON(formValues.validTo.value),
        chargingSchedule: this.chargingSchedule
      })

      this.profile = Object.assign({}, this.profile, {
        name: formValues.name.value,
        chargingProfile: JSON.stringify(this.chargingProfile),
      })

      if(this.profile.id && this.profile.id > 0) {
        const response = await this.stationsService.updateSmartChargingProfile(
          this.profile.id,
          this.profile.name,
          this.profile.chargingProfile,
        ) 

        if(response) {
          this.toastService.success('Charging Profile successfully updated')
          this.$router.push(`/charging-profiles`)
        } else {
          this.toastService.error('Failed to update charging profile')
        }
      } else {
        const response = await this.stationsService.addSmartChargingProfile(
          this.profile.name,
          this.profile.chargingProfile,
        )

        if(response) {
          this.toastService.success('Charging Profile successfully added')
          this.$router.push(`/charging-profiles`)
        } else {
          this.toastService.error('Failed to add charging profile')
        }
      }
    },
    duplicateProfile: async function() {
      const response = await this.stationsService.addSmartChargingProfile(
        this.profile.name,
        this.profile.chargingProfile,
      )

      if(response) {
        this.toastService.success('Charging Profile successfully duplicated')
        this.$router.push(`/charging-profiles`)
      } else {
        this.toastService.error('Failed to duplicate charging profile')
      }
    }
  },
  mounted() {
    this.getProfileById()
  }
}
</script>

<template>
<div class="profile-edit page">
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="container-fluid">
    <form v-on:submit.prevent="saveProfile">
      <div class="row page-header-container">
        <div class="page-header">
          <h1>{{ this.profile.name || 'Add new profile' }}</h1>
          <div class="main-commands">
            <input 
              type="button" 
              @click="duplicateProfile()"
              class="btn btn-info text-info main-command-btn" 
              name="duplicate" 
              value="Duplicate">
            <input 
              type="submit" 
              class="btn btn-success text-success main-command-btn" 
              name="submit" 
              value="Save">
            <input 
              type="button" 
              @click="showCancelStationEditModal()" 
              class="btn btn-warning text-warning main-command-btn" 
              name="submit" 
              value="Cancel">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-wrapper row">
            <div class="form-field col-md-6 col-sm-12">
              <label for="name">Name*</label>
              <input 
                type="text" 
                class="form-control" 
                name="name" 
                :value="profile.name" 
                placeholder="Enter Profile Name (Required)"
                required>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="chargingProfileId">OCPP Charging Profile Id*</label>
              <input 
                type="number" 
                class="form-control" 
                name="chargingProfileId" 
                :value="chargingProfile.chargingProfileId" 
                placeholder="Enter Charging Profile Id (Required)"
                required>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="transactionId">Transaction Id</label>
              <input 
                type="number" 
                class="form-control" 
                name="transactionId" 
                :value="chargingProfile.transactionId" 
                placeholder="Enter Transaction Id">
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="stackLevel">Stack Level*</label>
              <input 
                type="number" 
                min="0"
                class="form-control" 
                name="stackLevel" 
                :value="chargingProfile.stackLevel" 
                placeholder="Enter Stack Level (Required)"
                required>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="chargingProfilePurpose">Charging Profile Purpose*</label>
              <select
                class="form-control" 
                name="chargingProfilePurpose" 
                :value="chargingProfile.chargingProfilePurpose" 
                placeholder="Select Charging Profile Purpose"
                required>
                <option disabled value="">Select Charging Profile Purpose</option>
                <option value="ChargePointMaxProfile">ChargePointMaxProfile</option>
                <option value="TxDefaultProfile">TxDefaultProfile</option>
                <option value="TxProfile">TxProfile</option>
              </select>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="chargingProfileKind">Charging Profile Kind*</label>
              <select 
                class="form-control" 
                name="chargingProfileKind" 
                :value="chargingProfile.chargingProfileKind" 
                placeholder="Select Charging Profile Kind"
                required>
                <option disabled value="">Select Charging Profile Kind</option>
                <option value="Absolute">Absolute</option>
                <option value="Recurring">Recurring</option>
                <option value="Relative">Relative</option>
              </select>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="recurrencyKind">Recurrency Kind</label>
              <select
                class="form-control" 
                name="recurrencyKind" 
                :value="chargingProfile.recurrencyKind" 
                placeholder="Enter Recurrency Kind">
                <option value="">None</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
              </select>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="validFrom">Valid From</label>
              <input 
                type="datetime-local" 
                class="form-control" 
                name="validFrom" 
                :value="dateToHTML(chargingProfile.validFrom)" 
                placeholder="Enter Valid From">
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="validTo">Valid To</label>
              <input 
                type="datetime-local" 
                class="form-control" 
                name="validTo" 
                :value="dateToHTML(chargingProfile.validTo)" 
                placeholder="Enter Valid To">
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="duration">Duration</label>
              <input 
                type="number" 
                class="form-control" 
                name="duration" 
                :value="chargingSchedule.duration" 
                placeholder="Enter Duration">
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="startSchedule">Start Schedule</label>
              <input 
                type="datetime-local" 
                class="form-control" 
                name="startSchedule" 
                :value="dateToHTML(chargingSchedule.startSchedule)" 
                placeholder="Enter Start Schedule">
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="chargingRateUnit">Charging Rate Unit*</label>
              <select 
                class="form-control" 
                name="chargingRateUnit" 
                :value="chargingSchedule.chargingRateUnit" 
                placeholder="Select Charging Rate Unit"
                required>
                <option disabled value="">Select Charging Rate Unit</option>
                <option value="W">W</option>
                <option value="A">A</option>
              </select>
            </div>

            <div class="form-field col-md-6 col-sm-12">
              <label for="minChargingRate">Min Charging Rate</label>
              <input 
                type="number" 
                step="0.1"
                class="form-control" 
                name="minChargingRate" 
                :value="chargingSchedule.minChargingRate" 
                placeholder="Enter Min Charging Rate">
            </div>

            <div class="form-field col-sm-12">
              <label for="chargingSchedulePeriod">Charging Schedule Period* ([{"limit":xx, "startPeriod":xx.x, "numberPhases":x}, ...])</label>
              <textarea 
                rows="5" 
                class="form-control" 
                name="chargingSchedulePeriod" 
                :value="JSON.stringify(chargingSchedule.chargingSchedulePeriod)" 
                placeholder='Enter Charging Schedule Period (Required) JSON Format: [{"limit":xx, "startPeriod":xx.x, "numberPhases":x}, ...]'
                required>
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <cancel-station-edit-modal
    :visible="this.isCancelStationEditModalVisible"
    :station="profile"
    @closed="closeCancelStationEditModal()"
    @submit="onCancelStationEditModalSubmit">
  </cancel-station-edit-modal>
</div>
</template>

<style lang="scss" scoped>
.page.profile-edit {
  padding: 0px!important;
}

.btn {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
}

.form-wrapper {
  padding: 0px 0px;
  margin: 0px auto;

  .form-field {
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.with-checkbox {
      padding: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      label {
        margin: 0px;
        padding-right: 10px;
      }
    }

    label {
      font-size: 14px;
      font-weight: 800;
    }
  }
}
</style>
