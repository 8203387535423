<script>
import StationsService from '@/services/api/ocpp/StationsService'
import ToastService from '@/services/ToastService'

import StringUtils from '@/utils/StringUtils'

import DeleteStationModal from '@/components/modals/DeleteStationModal.vue'

export default {
  name: 'StationsListPage',
  components: {
    DeleteStationModal
  },
  data: function() {
    return {
      isLoading: true,
      filters: {
        name: null,
        vendorId: null
      },
      stations: [],
      selectedStation: null,
      isDeleteStationModalVisible: false,
      stationsService: new StationsService(this),
      toastService: new ToastService(this.$toast),
      stringUtils: new StringUtils()
    }
  },
  methods: {
    getStations: async function() {
      this.stations = await this.stationsService.getStations()

      if(!this.stations) {
        this.toastService.error('Failed to load stations')
      }
    },
    showDeleteStationModal: function (station) {
      this.selectedStation = station
      this.isDeleteStationModalVisible = true
    },
    onDeleteStationModalSubmit: async function() {
      const response = await this.stationsService.deleteStation(
        this.selectedStation.id
      )

      if(response) {
        this.stations = this.stations
          .filter(s => s.id !== this.selectedStation.id)
        this.selectedStation = null

        this.toastService.success('Station successfully deleted')
      } else {
        this.toastService.error('Failed to delete station')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(
        this.onIncomingNotification, 
        [
          'CHARGE_POINT_CONNECTED',
          'CHARGE_POINT_DISCONNECTED'
        ]
      )
    },
    onIncomingNotification: function () {
      // update stations
      this.getStations()
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getStations()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="station-list page">
    <div v-if="!stations || stations.length === 0" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>stations</strong> found</p>
    </div>
    <table v-else class="stations-table">
      <thead>
        <th>ID</th>
        <th>Name</th>
        <th>Chargebox ID</th>
        <th>Software version</th>
        <th>Activation</th>
        <th>Network</th>
        <th>Info</th>
        <th>Edit</th>
        <th>Delete</th>
      </thead>
      <transition-group tag="tbody" name="station-list" move-class="new">
        <tr v-for="station of this.stations" 
          :key="station.id"
          :class="{ station, verified: station.isVerified }">
          <td>{{ station.id }}</td>
          <td>{{ station.name }}</td>
          <td>{{ station.chargeboxID }}</td>
          <td>{{ station.softwareVersion || '-' }}</td>
          <td>
            <b-badge v-if="station.activationStatus" 
              :class="'pill activation-status-' + stringUtils.propToClass(station.activationStatus)">
              {{ station.activationStatus.toUpperCase() }}
            </b-badge>
          </td>
          <td>
            <b-badge v-if="station.networkStatus" 
              :class="'pill network-status-' + stringUtils.propToClass(station.networkStatus)">
              {{ station.networkStatus.toUpperCase() }}
            </b-badge>
          </td>
          <td>
            <router-link :to="'/stations/' + station.id"
              class="text-info">
              <img class="action-btn" src="../../assets/actions/info.svg">
            </router-link>
          </td>
          <td>
            <router-link :to="'/stations/' + station.id + '/edit'"
              class="text-primary">
              <img class="action-btn" src="../../assets/actions/edit.svg">
            </router-link>
          </td>
          <td>
            <button @click="showDeleteStationModal(station)" class="text-danger">
              <img class="action-btn" src="../../assets/actions/delete.svg">
            </button>
          </td>
        </tr>
      </transition-group>
    </table>

    <delete-station-modal
      :visible="this.isDeleteStationModalVisible"
      :station="this.selectedStation"
      @closed="() => { this.isDeleteStationModalVisible = false }"
      @submit="onDeleteStationModalSubmit">
    </delete-station-modal>
  </div>
</template>

<style lang="scss" scoped>
.station-list.page {
  padding: 0px!important;
}
</style>
