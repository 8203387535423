<script>
import StationsService from '../../services/api/ocpp/StationsService'
import ToastService from '../../services/ToastService'

import DeleteStationModal from '../../components/modals/DeleteStationModal.vue'
import CancelStationEditModal from '../../components/modals/CancelStationEditModal'

export default {
  name: 'StationEditPage',
  components: {
    DeleteStationModal,
    CancelStationEditModal
  },
  data: function () {
    return {
      isLoading: true,
      isDeleteStationModalVisible: false,
      isCancelStationEditModalVisible: false,
      station: {
        profileId: 0,
      },
      stationsModels: [],
      stationsService: new StationsService(this),
      toastService: new ToastService(this.$toast),
      imageUrl: '',
      placeholderUrl: 'https://via.placeholder.com/600x300.png?text=No+Image'
    }
  },
  methods: {
    goBack: function () {
      this.$router.push('/stations')
    },
    showCancelStationEditModal: function () {
      this.isCancelStationEditModalVisible = true;
    },
    closeCancelStationEditModal: function () {
      this.isCancelStationEditModalVisible = false;
    },
    onCancelStationEditModalSubmit: function () {
      this.goBack()
    },
    showDeleteStationModal: function () {
      this.isDeleteStationModalVisible = true;
    },
    closeDeleteStationModal: function () {
      this.isDeleteStationModalVisible = false;
    },
    onDeleteStationModalSubmit: async function () {
      const response = await this.stationsService.deleteStation(this.station)

      if (response) {
        this.toastService.success('Station successfully deleted')
      } else {
        this.toastService.error('Failed to delete station')
      }
    },
    getStationsModels: async function () {
      this.isLoading = true
      this.stationsModels = await this.stationsService.getStationModels()
      if (!this.stationsModels) {
        this.toastService.error('Failed to load stations models')
      }
      this.isLoading = false;
    },
    getStationById: async function () {
      this.isLoading = true;

      if (this.$route.params.id) {
        this.station = await this.stationsService.getStationById(this.$route.params.id)

        this.imageUrl = this.station.downscaledImageUrl

        if (!this.station) {
          this.toastService.error('Station not found')
        }
      }

      this.isLoading = false;
    },
    checkDefaultConfig: function (defaultConfig) {
      if (defaultConfig == '')
        return true
      try {
        const cfg = JSON.parse(defaultConfig)
        for (const kv of cfg) {
          if (!('key' in kv && 'value' in kv))
            return false
        }
      } catch (e) {
        return false
      }
      return true
    },
    saveStation: async function (event) {
      const formValues = event.target.elements

      if (!this.checkDefaultConfig(formValues.defaultConfig.value)) {
        this.toastService.error('Invalid Default Configuration Format')
        return
      }

      this.station = Object.assign({}, this.station, {
        name: formValues.name.value,
        organization: formValues.organization.value,
        chargeboxID: formValues.chargeboxID.value,
        groupID: formValues.groupID.value,
        stationModelsID: formValues.stationModelsID.value,
        address: formValues.address.value,
        latitude: formValues.latitude.value,
        longitude: formValues.longitude.value,
        description: formValues.description.value,
        defaultConfig: formValues.defaultConfig.value != '' ? formValues.defaultConfig.value : null,
        isReservationsEnabled: formValues.isReservationsEnabled.checked
          ? 'Enabled'
          : 'Disabled'
      })

      if (this.station.id && this.station.id > 0) {
        const response = await this.stationsService.updateStation(this.station)

        if (response) {
          this.toastService.success('Station successfully updated')
          this.$router.push(`/stations/${this.station.id}`)
        } else {
          this.toastService.error('Failed to updated station')
        }
      } else {
        const response = await this.stationsService.addStation(this.station)

        if (response) {
          this.toastService.success('Station successfully added')
          this.$router.push(`/stations/${response.id}`)
        } else {
          this.toastService.error('Failed to add station')
        }
      }
    },
    onDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (!file || !file.type.match(/image.*/)) return;

      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    },
    replaceImage() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/jpeg, image/jpg, image/png';
      input.onchange = async (e) => {
        const image = e.target.files[0];
        this.previewImage(image);

        const uploadedImages = await this.stationsService.uploadStationImage(this.station.id, image)
        if (!uploadedImages) {
          this.toastService.error('Failed to upload station image')
        } else {
          this.toastService.success('Successfully uploaded new image!')
        }


      };
      input.click();
    },
    async removeImage() {
      this.imageUrl = null;

      const station = await this.stationsService.removeStationImage(this.station.id)
      if (!station) {
        this.toastService.error('Failed to remove station image')
      } else {
        this.toastService.success('Successfully removed station image!')
      }
    }
  },
  mounted() {
    this.getStationById()
    this.getStationsModels()
  }
}
</script>

<template>
  <div class="station-edit page">
    <div v-if="isLoading" class="loading-indicator">
      <p>Loading data, please wait...</p>
    </div>
    <div v-else class="container-fluid">
      <form v-on:submit.prevent="saveStation">
        <div class="row page-header-container">
          <div class="page-header">
            <h1>{{ this.station.name || 'Add new station' }}</h1>
            <div class="main-commands">
              <input type="submit" class="btn btn-success text-success main-command-btn" name="submit" value="Save">
              <input type="button" @click="showCancelStationEditModal()"
                class="btn btn-warning text-warning main-command-btn" name="submit" value="Cancel">
              <input v-if="this.station && this.station.id < 0" @click="showDeleteStationModal()" type="button"
                class="btn btn-danger text-danger main-command-btn" name="submit" value="Delete">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-field col-sm-12">

            <div class="form-wrapper row">

              <div class="form-field col-md-12 col-sm-12">
                <div class="form-wrapper row">
                  <div class="col-md-12">
                    <label for="name">Station Image</label>
                    <div class="p-3" id="imageDropZone" @drop.prevent="onDrop" @dragover.prevent>
                      <img v-if="imageUrl" class="img-fluid img-preview" :src="imageUrl" alt="Image Preview">
                      <img v-else class="img-fluid img-preview"
                        src="https://via.placeholder.com/300x300.png?text=No+Image" alt="Placeholder Image">
                      <div class="overlay">
                        <button class="btn btn-primary mr-2" @click.prevent="replaceImage">{{ imageUrl ? 'Replace' :
      'Upload' }}</button>
                        <button class="btn btn-danger" @click.prevent="removeImage" v-show="imageUrl">Remove</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="name">Name</label>
                <input type="text" class="form-control" name="name" :value="station.name"
                  placeholder="Enter station name">
              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="organization">Organization</label>
                <input type="text" class="form-control" name="organization" :value="station.organization"
                  placeholder="Enter organization name">
              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="chargebox-id">Chargebox ID</label>
                <input type="text" class="form-control" name="chargeboxID" :value="station.chargeboxID"
                  placeholder="Enter chargebox ID">
              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="group-id">Group ID</label>
                <input type="text" class="form-control" name="groupID" :value="station.groupID"
                  placeholder="Enter group ID">
              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="station-models-id">Station model</label>
                <select class="form-control" name="stationModelsID" :value="station.stationModelsID"
                  placeholder="Select station model">
                  <option value="" :selected="!station.stationModelsID">No model</option>
                  <option v-for="stationModel of this.stationsModels" v-bind:key="stationModel.id"
                    :value="stationModel.id" :selected="station.stationModelsID === stationModel.id">
                    {{ `${stationModel.model} (${stationModel.manufacturer})` }}
                  </option>
                </select>
              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="address">Address</label>
                <input type="text" class="form-control" name="address" :value="station.address"
                  placeholder="Enter location address">
              </div>
              <div class="form-field col-md-6 col-sm-12">
                <label for="latitude">Latitude</label>
                <input type="text" class="form-control" name="latitude" :value="station.latitude"
                  placeholder="Enter location latitude">
              </div>

              <div class="form-field col-md-6 col-sm-12">
                <label for="longitude">Longitude</label>
                <input type="text" class="form-control" name="longitude" :value="station.longitude"
                  placeholder="Enter location longitude">
              </div>

              <div class="form-field col-sm-12">
                <label for="description">Description</label>
                <textarea rows="5" class="form-control" name="description" :value="station.description"
                  placeholder="Enter station description">
              </textarea>
              </div>

              <div class="form-field col-sm-12">
                <label for="defaultConfig">Default Configuration in JSON: [{"key": "...", "value": "..."}, ...]</label>
                <textarea rows="5" class="form-control" name="defaultConfig" :value="station.defaultConfig"
                  placeholder="Enter station's default config">
              </textarea>
              </div>

              <div class="form-field with-checkbox col-md-6 col-sm-12">
                <label class="label-container">Are reservations enabled?
                  <input type="checkbox" name="isReservationsEnabled"
                    :checked="this.station.isReservationsEnabled === 'Enabled'">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <delete-station-modal :visible="this.isDeleteStationModalVisible" :station="station"
      @closed="closeDeleteStationModal()" @submit="onDeleteStationModalSubmit">
    </delete-station-modal>
    <cancel-station-edit-modal :visible="this.isCancelStationEditModalVisible" :station="station"
      @closed="closeCancelStationEditModal()" @submit="onCancelStationEditModalSubmit">
    </cancel-station-edit-modal>
  </div>
</template>

<style lang="scss" scoped>
.page.station-edit {
  padding: 0px !important;
}

.btn {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
}

.form-wrapper {
  padding: 0px 0px;
  margin: 0px auto;

  .form-field {
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.with-checkbox {
      padding: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      label {
        margin: 0px;
        padding-right: 10px;
      }
    }

    label {
      font-size: 14px;
      font-weight: 800;
    }
  }
}

#imageDropZone {
  border: 2px dashed #b1b1b1;
  cursor: pointer;
  position: relative;
}

.img-preview {
  width: 200;
  height: 200px;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

#imageDropZone:hover .overlay {
  opacity: 1;
}
</style>