<script>
import ToastService from '../../services/ToastService'

export default {
  name: 'NotificationsChannel',
  props: [ 'isCollapsed', 'notificationsService' ],
  data: function() {
    return {
      isLoading: false,
      isVisible: true,
      connected: false,
      notifications: [],
      toastService: new ToastService(this.$toast)
    }
  },
  computed: {
    toggleButtonText: function() {
      return !this.isVisible 
        ? 'Show notifications channel' 
        : 'Hide notifications channel'
    }
  },
  methods: {
    toggleChannelButton: function() {
      this.isVisible = !this.isVisible ? true : false
    },
    getNotifications: async function() {
      this.isLoading = true;

      this.notifications = await this.notificationsService.getNotifications({
        stationId: null
      })

      this.isLoading = false
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification)
    },
    onIncomingNotification: function (notification) {
      if(!this.notifications) {
        this.notifications = []
      }

      this.notifications.unshift(notification)
    }
  },
  mounted() {
    this.connectToChannel()
    this.getNotifications()
  }
}
</script>

<template>
  <div :class="{ 
      'notifications-channel-container': true, 
      'collapsed': this.isCollapsed 
    }">
    <h4 class="title-container">
      <router-link to="/notifications">
        <span class="recording-indicator">
          <span class="circle"></span>
        </span>
        <span class="title">Notifications channel</span>
      </router-link>
    </h4>
    <div v-if="notifications && notifications.length" class="notifications-channel">
      <transition-group tag="div" name="notifications-channel-list" move-class="new">
        <div v-for="notification of notifications" 
          :key="notification.id"
          :class="{ 
            notification: true, 
            error: (notification.status || '').toUpperCase() === 'ERROR', 
            success: (notification.status || '').toUpperCase() === 'SUCCESS',
            warning: (notification.status || '').toUpperCase() === 'WARNING'
            }">
            <span class="date">{{ $moment(notification.date).format("HH:mm:ss, MMMM Do YYYY") }}</span>
            <router-link :to="'/notifications/' + notification.id" class="title">
              {{ notification.title }}
            </router-link>
            <div class="direction">
              <b-badge :class="'pill notification-direction-' + (notification.direction || 'SYSTEM').toLowerCase().replace('none', 'system').replace('_','-')">
               <img src="../../assets/system.svg" v-if="!notification.direction || notification.direction.toUpperCase() === 'NONE' || notification.direction.toUpperCase() === 'SYSTEM'">
               <img src="../../assets/inbound.svg" v-if="notification.direction && notification.direction.toUpperCase() === 'INBOUND'">
               <img src="../../assets/outbound.svg" v-if="notification.direction && notification.direction.toUpperCase() === 'OUTBOUND'">
                {{ (notification.direction || "SYSTEM").toUpperCase().replace("NONE", "SYSTEM") }}
              </b-badge>
            </div>
        </div>
      </transition-group>
    </div>
    <div class="no-notifications" v-else>
      <img class="action-btn" src="../../assets/actions/no_notifications.svg">
      <p class="message">No notifications received yet</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notifications-channel-container {
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(0);
  transition: all 0.3s linear;

  &.collapsed {
    transform: translateX(100%);
  }

  .no-notifications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 100%;
    padding-bottom: 48px;

    img {
      height: 120px;
      width: 120px;
    }

    .message {
      margin: 0px auto;
      padding: 0px;
    }
  }

  .title-container {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    cursor: pointer;

    &:hover .title {
      opacity: 1;
    }

    .title {
      transition: all 0.5s ease;
      transform: translate3d(0);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      color: #fff;
      opacity: 0.7;
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
    }

    a {
      margin: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-decoration: none!important;
    }

    .recording-indicator {
      position: relative;

      .circle {
        position: absolute;
        top: -6px;
        left: 0;
        height: 10px;
        width: 10px;
        border-radius:50%;
        background-color:red;
        -webkit-transition:height .25s ease, width .25s ease;
        transition:height .25s ease, width .25s ease;

        &:before,
        &:after {
          content:'';
          display:block;
          position: absolute;
          top:0; right:0; bottom:0; left:0;
          border-radius:50%;
          border:1px solid red;
        }

        &:before {
          -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
        }
        
        &:after {
          -webkit-animation: ripple 2s linear 1s infinite;
          animation: ripple 2s linear 1s infinite;
        }
      }
    }
  }

  .notification {
    padding: 0px 12px 8px 12px;
    margin: 16px 12px 16px 12px;
    border-left: 4px solid rgba(245, 245, 245, 0.2);
    transition: all 0.3s ease;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &.error {
      color: #DC3545;
      border-color: #DC3545;
    }

    &.success {
      color: #218838;
      border-color: #218838;
    }

    &.warning {
      color: #ffc107;
      border-color: #ffc107;
    }

    .date {
      display: block;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 8px;
    }

    a {
      font-weight: 500;
      text-decoration: none;
      font-size: 15px;
      color: #fff!important;
    }
  }
}

@-webkit-keyframes ripple{
  0% {-webkit-transform:scale(1); }
  75% {-webkit-transform:scale(1.75); opacity:1;}
  100% {-webkit-transform:scale(2); opacity:0;}
}

@keyframes ripple{
  0% {transform:scale(1); }
  75% {transform:scale(1.75); opacity:1;}
  100% {transform:scale(2); opacity:0;}
}
</style>
