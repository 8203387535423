<script>
export default {
  name: 'ClearChargingScheduleModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      id: '',
      idState: null,
      connectorId: '',
      connectorIdState: null,
      chargingProfilePurpose: '',
      chargingProfilePurposeState: null,
      stackLevel: '',
      stackLevelState: null,
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      return valid
    },
    resetModal: function() {
      this.id = ''
      this.idState = null
      this.connectorId = ''
      this.connectorIdState = null
      this.chargingProfilePurpose = ''
      this.chargingProfilePurposeState = null
      this.stackLevel = ''
      this.stackLevelState = null
      this.$emit("closed")
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
        
      this.$emit("submit", {
        id: this.id,
        connectorId: this.connectorId,
        chargingProfilePurpose: this.chargingProfilePurpose,
        stackLevel: this.stackLevel
      })

      this.$nextTick(() => {
        this.$refs.clearChargingScheduleModal.hide()
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.clearChargingScheduleModal.show()
      } else {
        this.$refs.clearChargingScheduleModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="clear-charging-schedule modal">
    <b-modal
      id="clear-charging-schedule-modal"
      ref="clearChargingScheduleModal"
      title="Clear Charging Profile"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="idState"
          label="Charging Profile ID"
          label-for="id-input">
          <b-form-input
            id="id-input"
            v-model="id"
            :state="idState">
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="connectorIdState"
          label="Connector ID"
          label-for="connector-id-input">
          <b-form-input
            id="connector-id-input"
            v-model="connectorId"
            :state="connectorIdState">
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="chargingProfilePurposeState"
          label="Charging Profile Purpose"
          label-for="charging-profile-purpose-type">
          <b-form-select
            id="charging-profile-purpose-type"
            v-model="chargingProfilePurpose"
            :state="chargingProfilePurposeState">
            <option value="">
            </option>
            <option value="ChargePointMaxProfile">
              ChargePointMaxProfile
            </option>
            <option value="TxDefaultProfile">
              TxDefaultProfile
            </option>
            <option value="TxProfile">
              TxProfile
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          :state="stackLevelState"
          label="Stack Level"
          label-for="stack-level-input">
          <b-form-input
            id="stack-level-input"
            v-model="stackLevel"
            :state="stackLevelState">
          </b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>