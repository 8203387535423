'use strict'

import ApiService from '../ApiService'

export default class ReservationsService extends ApiService {
  async getReservations () {
    return this._sendRequest({
      url: '/reservations'
    })
  }

  async getReservationById (reservationId) {
    return this._sendRequest({
        url: `/reservations/${reservationId}`,
        method: 'GET'
      })
  }

  async getReservationLogMessagesById (reservationId) {
    return this._sendRequest({
        url: `/reservations/log?reservationId=${reservationId}`,
        method: 'GET'
      })
  }
}
