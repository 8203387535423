<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

export default {
  name: 'MeterValuesPage',
  data: function() {
    return {
      isLoading: true,
      meterValues: null,
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    getMeterValues: async function() {
      this.meterValues = await this.trackingService.getMeterValues()

      if(!this.meterValues) {
        this.toastService.error('Failed to fetch Meter Values')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification, [
        'RECEIVED_METER_VALUES_REQUEST'
      ])
    },
    onIncomingNotification: function () {
      this.getMeterValues()
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getMeterValues()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="meter-values page">
    <div v-if="!meterValues || meterValues.length === 0" 
      class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>meter values</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="meter-values-table">
        <thead>
          <th>Created At</th>
          <th>Chargebox ID</th>
          <th>Connector ID</th>
          <th>Transaction</th>
          <th>Current</th>
          <th>Current Offered</th>
          <th>Energy</th>
          <th>Power</th>
          <th>SOC</th>
          <th>Voltage</th>
        </thead>
        <transition-group tag="tbody" name="meter-values-list" move-class="new">
          <tr v-for="meterValue of meterValues" 
            :key="meterValue.time">
            <td>{{ $moment(meterValue.time).format("MM/DD/YYYY HH:mm:ss") }}</td>
            <td>{{ meterValue.chargeboxID }}</td>
            <td>{{ meterValue.connectorID || '/' }}</td>
            <td>{{ meterValue.transactionID || '/' }}</td>
            <td>{{ meterValue.currentA.toFixed(2) }} <strong>A</strong></td>
            <td>{{ meterValue.currentOfferedA.toFixed(2) }} <strong>A</strong></td>
            <td>{{ ((meterValue.energy || 0) / 1000).toFixed(3) }} <strong>kWh</strong></td>
            <td>{{ ((meterValue.power || 0) / 1000).toFixed(3) }} <strong>kW</strong></td>
            <td>{{ meterValue.soc }} <strong>%</strong></td>
            <td>{{ meterValue.voltageV.toFixed(2) }} <strong>V</strong></td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.meter-values.page {
  padding: 0px!important;
  width: 100%;
}

td {
  white-space: pre;

  strong {
    font-size: 12px;
  }
}
</style>
