<script>
export default {
  name: 'RemoteStopTransactionModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      connectorId: '',
      connectorIdState: null,
      transactionId: '',
      transactionIdState: null
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      this.connectorIdState = valid ? true : false
      return valid
    },
    resetModal: function() {
      this.connectorId = ''
      this.connectorIdState = null
      this.transactionId = ''
      this.transactionIdState = null
      this.$emit("closed")
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
      
      this.$emit("submit", {
        connectorId: this.connectorId,
        transactionId: this.transactionId
      })

      this.$nextTick(() => {
        this.$refs.remoteStopTransactionModal.hide()
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.remoteStopTransactionModal.show()
      } else {
        this.$refs.remoteStopTransactionModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="remote-stop-transaction modal">
    <b-modal
      id="remote-stop-transaction-modal"
      ref="remoteStopTransactionModal"
      title="Send remote stop transaction request"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" 
        @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="connectorIdState"
          label="Connector ID*"
          label-for="connector-id-input"
          invalid-feedback="Connector ID is required">
          <b-form-input
            id="connector-id-input"
            v-model="connectorId"
            :state="connectorIdState"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="transactionIdState"
          label="Transaction ID"
          label-for="transaction-id-input">
          <b-form-input
            id="transaction-id-input"
            v-model="transactionId"
            :state="transactionIdState">
          </b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>