<script>
export default {
  name: 'ShowCalendarEventModal',
  props: {
    visible: Boolean,
    onCollapse: Function,
    utilizationData: Object
  },
  data: function() {
    return {
      connectorId: '',
      connectorIdState: null
    }
  },
  methods: {

    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.showCalendarEventModal.hide()
      this.$emit("closed")
    },
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.showCalendarEventModal.show()
      } else {
        this.$refs.showCalendarEventModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="show-calendar-event modal">
    <b-modal
      id="show-calendar-event-modal"
      ref="showCalendarEventModal"
      title="Reservation Details"
      @cancel="handleOk"
      @close="handleOk"
      @ok="handleOk"
      hide-footer>
        <div v-if="this.utilizationData"> 
            <b-container class="bv-example-row">
                <b-row>
                    <b-col class="font-weight-bold">Reservation ID</b-col>
                    <b-col cols="6">{{ this.utilizationData.id }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Station Name</b-col>
                    <b-col cols="6">{{ this.utilizationData.stationName }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Port Name</b-col>
                    <b-col cols="6">{{ this.utilizationData.portName }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Driver Name</b-col>
                    <b-col cols="6">{{ this.utilizationData.firstName }} {{ this.utilizationData.lastName }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Username</b-col>
                    <b-col cols="6">{{ this.utilizationData.username }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Vehicle</b-col>
                    <b-col cols="6">{{ this.utilizationData.evMake }}, {{ this.utilizationData.evModel }}, {{ this.utilizationData.evYear }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Created</b-col>
                    <b-col cols="6">{{ this.utilizationData.createdAt }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Reservation Status</b-col>
                    <b-col cols="6">{{ this.utilizationData.reservationStatus }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Start Time</b-col>
                    <b-col cols="6">{{ this.utilizationData.startTime }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">End Time</b-col>
                    <b-col cols="6">{{ this.utilizationData.endTime }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Driver Predicted Mileage</b-col>
                    <b-col cols="6">{{ this.utilizationData.milesToCharge || "N/A" }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Charged Mileage</b-col>
                    <b-col cols="6">{{ this.utilizationData.evMileage || "N/A"}}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Average Charging Speed</b-col>
                    <b-col cols="6">{{ this.utilizationData.avgChargingSpeed || "N/A" }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Average Power</b-col>
                    <b-col cols="6">{{ this.utilizationData.avgPower || "N/A" }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">Total Energy</b-col>
                    <b-col cols="6">{{ this.utilizationData.totalEnergy || "N/A" }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="font-weight-bold">End Reason</b-col>
                    <b-col cols="6">{{ this.utilizationData.reservationEndReason || "Reservation Upcoming / Active" }}</b-col>
                </b-row>
            </b-container>
        </div>
        <b-button class="mt-3" block @click="handleOk">Close</b-button>
    </b-modal>
  </div>
</template>