<script>
import StationsService from '@/services/api/ocpp/StationsService'
import ToastService from '@/services/ToastService'

import StringUtils from '@/utils/StringUtils'

import DeleteProfileModal from '@/components/modals/DeleteProfileModal.vue'

import moment from 'moment'

export default {
  name: 'ChargingProfileListPage',
  components: {
    DeleteProfileModal
  },
  data: function() {
    return {
      isLoading: true,
      filters: {
        name: null,
        vendorId: null
      },
      profiles: [],
      selectedProfile: null,
      isDeleteProfileModalVisible: false,
      stationsService: new StationsService(this),
      toastService: new ToastService(this.$toast),
      stringUtils: new StringUtils()
    }
  },
  methods: {
    getProfiles: async function() {
      this.profiles = await this.stationsService.getSmartChargingProfiles()

      if(!this.profiles) {
        this.toastService.error('Failed to load charging profiles')
      }

      this.profiles.forEach(profile => {
        profile.chargingProfileJSON = JSON.parse(profile.chargingProfile)
      });

      this.profiles.sort(function(p1, p2) {
        const p1ValidTo = p1.chargingProfileJSON.validTo
        const p2ValidTo = p2.chargingProfileJSON.validTo
        if (p1ValidTo && p2ValidTo) {
          const p1ValidToMoment = moment(p1ValidTo)
          const p2ValidToMoment = moment(p2ValidTo)
          if (p1ValidToMoment.isBefore(p2ValidToMoment)) return 1
          else if (p2ValidToMoment.isBefore(p1ValidToMoment)) return -1
          else return 0
        } else if (p1ValidTo) {
          if (moment(p1ValidTo).isAfter()) return -1
          else return 1
        } else if (p2ValidTo) {
          if (moment(p2ValidTo).isAfter()) return 1
          else return -1
        } else {
          return 0
        }
      })
    },
    getValidTo: function(profile) {
      const chargingProfile = profile.chargingProfileJSON

      if (chargingProfile.validTo) return moment(chargingProfile.validTo).format('MMMM Do YYYY, hh:mm A')
      else return 'None'
    },
    checkExpiry: function(profile) {
      const chargingProfile = profile.chargingProfileJSON

      if (chargingProfile.validTo) return moment(chargingProfile.validTo).isBefore()
      else return false
    },
    showDeleteProfileModal: function (profile) {
      this.selectedProfile = profile
      this.isDeleteProfileModalVisible = true
    },
    onDeleteProfileModalSubmit: async function() {
      const response = await this.stationsService.deleteSmartChargingProfile(
        this.selectedProfile.id
      )

      if(response) {
        this.profiles = this.profiles
          .filter(s => s.id !== this.selectedProfile.id)
        this.selectedProfile = null

        this.toastService.success('Profile successfully deleted')
      } else {
        this.toastService.error('Failed to delete profile')
      }
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getProfiles()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="profile-list page">
    <div v-if="!profiles || profiles.length === 0" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>charging profiles</strong> found</p>
    </div>
    <table v-else class="profiles-table">
      <thead>
        <th>ID</th>
        <th>Name</th>
        <th>Valid To</th>
        <th>Info</th>
        <th>Delete</th>
      </thead>
      <transition-group tag="tbody" name="profile-list" move-class="new">
        <tr v-for="profile of this.profiles" 
          :key="profile.id"
          :class="{ profile, expired: checkExpiry(profile) }">
          <td>{{ profile.id }}</td>
          <td>{{ profile.name }}</td>
          <td>{{ getValidTo(profile) }}</td>
          <td>
            <router-link :to="'/charging-profiles/' + profile.id"
              class="text-info">
              <img class="action-btn" src="../../assets/actions/info.svg">
            </router-link>
          </td>
          <td>
            <button @click="showDeleteProfileModal(profile)" class="text-danger">
              <img class="action-btn" src="../../assets/actions/delete.svg">
            </button>
          </td>
        </tr>
      </transition-group>
    </table>

    <delete-profile-modal
      :visible="this.isDeleteProfileModalVisible"
      :profile="this.selectedProfile"
      @closed="() => { this.isDeleteProfileModalVisible = false }"
      @submit="onDeleteProfileModalSubmit">
    </delete-profile-modal>
  </div>
</template>

<style lang="scss" scoped>
.profile-list.page {
  padding: 0px!important;
}
.expired {
  // text-decoration: line-through;
  color: red;
}
</style>
