'use strict'

export default class ToastService {
  constructor(toastInstance) {
    this.$toast = toastInstance;
  }

  info (message) {
    this.$toast.open({
      message: message,
      type: 'info',
      position: 'top-right',
      dismissible: false
    })
  }

  success (message) {
    this.$toast.open({
      message: message,
      type: 'success',
      position: 'top-right',
      dismissible: false
    })
  }

  error (message) {
    this.$toast.open({
      message: message,
      type: 'error',
      position: 'top-right',
      dismissible: false
    })
  }
}
