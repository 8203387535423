<script>
export default {
  name: 'LogoutModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  methods: {
    handleLogout: function() {
      localStorage.removeItem('user')
      localStorage.removeItem('usertoken')
      this.$emit("submit")
    },
    handleClose: function() {
      this.$emit("closed")
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.logoutModal.show()
      } else {
        this.$refs.logoutModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="logout modal">
    <b-modal
      id="logout-modal"
      ref="logoutModal"
      title="Sign out"
      okTitle="Sign out"
      cancelTitle="Cancel"
      @cancel="handleClose"
      @close="handleClose"
      @ok="handleLogout">
      <div class="modal-body">
        <p>Are you sure you want to sign out?</p>
      </div>
    </b-modal>
  </div>
</template>