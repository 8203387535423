<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

import StringUtils from '@/utils/StringUtils'

export default {
  name: 'EvseStatusListPage',
  data: function() {
    return {
      isLoading: true,
      evseStatuses: null,
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast),
      stringUtils: new StringUtils()
    }
  },
  methods: {
    getEvseStatus: async function() {
      this.evseStatuses = await this.trackingService.getEvseStatus()

      if(!this.evseStatuses) {
        this.toastService.error('Failed to fetch EVSE Status')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification, [
        'RECEIVED_START_TRANSACTION_REQUEST',
        'RECEIVED_STOP_TRANSACTION_REQUEST',
        'RECEIVED_STATUS_NOTIFICATION_REQUEST'
      ])
    },
    onIncomingNotification: function () {
      // update EVSE status
      this.getEvseStatus()
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getEvseStatus()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="evse-status page">
    <div v-if="!evseStatuses || evseStatuses.length === 0" 
      class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>EVSE statuses</strong> found</p>
    </div>
    <table v-else class="evse-status-table">
      <thead>
        <th>Created At</th>
        <th>Name</th>
        <th>Chargebox ID</th>
        <th>Connector ID</th>
        <th>Transaction ID</th>
        <th>Meter Value</th>
        <!-- <th>Status</th>
        <th>Info</th> -->
      </thead>
      <transition-group tag="tbody" name="evse-status-list" move-class="new">
        <tr v-for="evse of evseStatuses" 
          :key="evse.time">
          <td>{{ $moment(evse.time).format("MM/DD/YYYY HH:mm:ss") }}</td>
          <td>{{ evse.name }}</td>
          <td>{{ evse.chargeboxID }}</td>
          <td>{{ evse.connectorID }}</td>
          <td>{{ evse.transactionID }}</td>
          <td>{{ evse.meterValue ? (evse.meterValue / 1000).toFixed(3) : 0.000 }} <strong>kWh</strong></td>
          <!-- <td>
            <b-badge v-if="evse && evse.status" 
              :class="'pill station-status-' + stringUtils.propToClass(evse.status)">
              {{ evse.status.toUpperCase().replace('_',' ') }}
            </b-badge>
          </td>
          <td>
            <router-link 
              :to="`/evse-status/${evse.chargeboxID}/${evse.connectorID}`"
              class="text-info">
              <img class="action-btn" src="../../assets/actions/info.svg">
            </router-link>
          </td> -->
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<style lang="scss">
.evse-status.page {
  padding: 0px!important;
  width: 100%;
}
</style>
