<script>
//import VueBootstrapTable from "vue2-bootstrap-table2";
// import VueMultiselect from 'vue-multiselect'
//import { tableValues } from './tableValues' // Dummy data
import Multiselect from '@vueform/multiselect'
import ReservationsService from '../../services/api/ocpp/ReservationsService'
import CacheService from '../../services/CacheService'

export default {
    name: 'ReservationsPage',
    components: {
        //VueBootstrapTable: VueBootstrapTable,
        Multiselect
    },
    data: function () {
        return {
            isLoading: false,
            reservations: [],
            filteredReservations: [],
            groups: [],
            stations: [],
            endReasons: [],
            types: [],
            driverIds: [],
            selectedGroups: [],
            selectedStations: [],
            selectedEndReasons: [],
            selectedTypes: [],
            selectedDriverIds: [],
            selectedStartTime: null,
            selectedEndTime: null,
            reservationsService: new ReservationsService(this),
            cacheService: new CacheService(),
            tableColumns: [
                {
                    name: "id",
                    title: "ID",
                },
                {
                    name: "startTime",
                    title: "Start Time",
                    renderfunction: this.renderColumn,
                    visible: true,
                },
                {
                    name: "endTime",
                    title: "End Time",
                    renderfunction: this.renderColumn,
                    visible: true,
                },
                {
                    name: "groupName",
                    title: "Group",
                    visible: true,
                },
                {
                    name: "stationName",
                    title: "Station Name",
                    visible: true,
                },
                {
                    name: "portName",
                    title: "Port Name",
                    visible: true,
                },
                {
                    name: "driverId",
                    title: "Driver ID",
                    visible: true,
                },
                {
                    name: "vehicle",
                    title: "Vehicle",
                    visible: true,
                    renderfunction: this.renderColumn
                },
                {
                    name: "totalEnergy",
                    title: "Total Energy",
                    visible: true,
                },
                {
                    name: "endReason",
                    title: "End Reason",
                    visible: true,
                    renderfunction: this.renderColumn
                },
                {
                    name: "type",
                    title: "Type",
                    visible: true,
                },
                {
                    name: "status",
                    title: "Status",
                    visible: true,
                    renderfunction: this.renderColumn
                },
            ],
            handleRowFunction: this.handleRow,
            currentPage: 1,
            totalRows: 0,
            fields: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "startTime",
                    label: "Start Time",
                    formatter: this.renderColumn,
                },
                {
                    key: "endTime",
                    label: "End Time",
                    formatter: this.renderColumn,
                },
                {
                    key: "groupName",
                    label: "Group",
                },
                {
                    key: "stationName",
                    label: "Station Name",
                },
                {
                    key: "portName",
                    label: "Port Name",
                },
                {
                    key: "driverId",
                    label: "Driver ID",
                },
                {
                    key: "vehicle",
                    label: "Vehicle",
                    //formatter: this.renderColumn
                },
                {
                    key: "totalEnergy",
                    label: "Total Energy",
                },
                {
                    key: "endReason",
                    label: "End Reason",
                    //formatter: this.renderColumn
                },
                {
                    key: "type",
                    label: "Type",
                },
                {
                    key: "status",
                    label: "Status",
                    //formatter: this.renderColumn
                },
            ],
            filter: null,
        }
    },
    methods: {
        getReservations: async function () {
            // first try to get from cache
            const reservationsKey = 'reservations'
            const cachedReservations = this.cacheService.getCache(reservationsKey)

            if (cachedReservations) {
                this.reservations = this.filteredReservations = cachedReservations
            } else {
                const reservations = await this.reservationsService.getReservations();

                if (!reservations)
                    return

                this.cacheService.setCache(reservationsKey, reservations)
                this.reservations = this.filteredReservations = reservations
            }

            //console.log(this.reservations)

            this.filterDistinctValues()

        },
        handleRow(item) {
            console.log("CLICK ROW: " + JSON.stringify(item));
            const reservationId = item.id
            if (!reservationId) {
                this.toastService.console.error('No reservation ID found');
                return
            }

            this.$router.push(`/reservations/${reservationId}`)

        },
        renderColumn(value, key, item) {
            if (key == "startTime") {
                return new Date(item.startTime).toLocaleDateString() + " " + new Date(item.startTime).toLocaleTimeString()
            } else if (key == "endTime") {
                return new Date(item.endTime).toLocaleDateString() + " " + new Date(item.endTime).toLocaleTimeString()
            }
        },
        getEndReasonBadgeVariant(endReason) {
            switch (endReason) {
                case 'Regular':
                    return 'success';
                case 'Missed':
                    return 'danger';
                case 'Canceled':
                case 'Canceled - Station Unavailable':
                    return 'warning';
                case 'Over':
                    return 'info'
                default:
                    return 'secondary';
            }
        },
        getStatusBadgeClass(status) {
            switch (status) {
                case "Reserved":
                    return 'badge-reserved';
                case "Waiting_Start_Charging":
                case "Waiting_Stop_Charging":
                case "Waiting_Pause_Charging":
                case "Waiting_Finish_Charging":
                    return 'badge-waiting';
                case "Charging":
                    return 'badge-charging';
                case "Paused":
                    return 'badge-paused';
                case "Finished":
                    return 'badge-finished';
                default:
                    return 'secondary';
            }
        },
        filterDistinctValues() {
            let distinctStations = new Set();
            let distinctGroups = new Set();
            let distinctDriverIds = new Set();
            let distinctEndReasons = new Set();
            let distinctTypes = new Set();

            for (const reservation of this.reservations) {
                distinctStations.add(reservation.stationName);
                distinctGroups.add(reservation.groupName);
                distinctDriverIds.add(reservation.driverId);
                distinctEndReasons.add(reservation.endReason);
                distinctTypes.add(reservation.type);
            }


            this.stations = Array.from(distinctStations)
            this.groups = Array.from(distinctGroups)
            this.driverIds = Array.from(distinctDriverIds)
            this.endReasons = Array.from(distinctEndReasons)
            this.types = Array.from(distinctTypes)

            this.stations = this.stations.map(station => {
                return { name: station, value: station }
            })
            this.groups = this.groups.map(group => {
                return { name: group, value: group }
            })
            this.driverIds = this.driverIds.map(driverId => { return { name: driverId, value: driverId } })
            this.endReasons = this.endReasons.map(endReason => { return { name: endReason, value: endReason } })
            this.types = this.types.map(type => { return { name: type, value: type } })
        },
        onFilterChange() {
            console.log("Filtering..")
            if (!this.selectedGroups.length && !this.selectedStations.length && !this.selectedEndReasons.length && !this.selectedTypes.length && !this.selectedDriverIds.length && !this.selectedStartTime && !this.selectedEndTime) {
                this.filteredReservations = this.reservations
                return
            }

            // Function to filter reservations based on filters
            const filters = {
                groupName: this.selectedGroups,
                stationName: this.selectedStations,
                endReason: this.selectedEndReasons,
                type: this.selectedTypes,
                driverId: this.selectedDriverIds,
            }

            this.filteredReservations = this.filterReservations(this.reservations, filters)

            this.totalRows = this.filteredReservations.length
            this.currentPage = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            console.log("Total items: " + filteredItems.length, this.filteredReservations.length)
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        filterReservations(reservations, filters) {
            const filteredReservations = reservations.filter(reservation => {
                // Check if each property in filters is either empty or matches the reservation
                return Object.keys(filters).every(property => {
                    if (filters[property].length === 0) {
                        return true;
                    }
                    // Check if reservation property matches any value in the filter array
                    return filters[property].some(filterItem => filterItem === reservation[property]);
                })
            });


            const epochStartTime = Date.parse(this.selectedStartTime) // Returns NaN for null, which is a falsy value
            const epochEndTime = Date.parse(this.selectedEndTime) // Returns NaN for null, which is a falsy value

            return filteredReservations.filter(reservation => {
                // Filter based on set selected start & end time, otherwise include the reservation
                if (epochStartTime && epochEndTime) {
                    return (reservation.startTime >= epochStartTime) && (reservation.endTime <= epochEndTime)
                } else if (epochStartTime) {
                    return (reservation.startTime >= epochStartTime)
                } else if (epochEndTime) {
                    return (reservation.endTime <= epochEndTime)
                } else {
                    return true
                }
            })
        }
    },
    watch: {
        selectedStartTime: function () {
            //this.selectedStartTime = newVal
            this.onFilterChange()
        },
        selectedEndTime: function () {
            //this.selectedEndTime = newVal
            this.onFilterChange()
        },
    },
    async mounted() {
        this.isLoading = true
        await this.getReservations()
        this.totalRows = this.filteredReservations.length
        this.isLoading = false
    },
}
</script>

<template>
    <div v-if="isLoading" class="loading-indicator">
        <p>Loading data, please wait...</p>
    </div>
    <div v-else class="utilization-list page">
        <div v-if="!reservations || reservations.length === 0" class="no-data-found">
            <img src="../../assets/actions/no_data.svg">
            <p>No <strong>reservations</strong> found</p>
        </div>
        <div v-else>



            <div class="row page-header-container">
                <div class="page-header">
                    <h1>Reservations</h1>
                </div>
            </div>
            <div class="container-fluid mx-3 my-5">
                <div class="row">
                    <div class="form-field col-md-2 col-sm-12">
                        <label for="stationName">Groups</label>
                        <Multiselect v-model="selectedGroups" :options="groups" mode="tags" :close-on-select="false"
                            placeholder="Select group(s)" label="name" track-by="name" @select="onFilterChange()" @deselect="onFilterChange()"
                            :show-labels="false">
                        </Multiselect>
                    </div>
                    <div class="form-field col-md-2 col-sm-12">
                        <label for="groupName">Stations</label>
                        <Multiselect v-model="selectedStations" :options="stations" mode="tags" :close-on-select="false"
                            placeholder="Select station(s)" label="name" track-by="name" @select="onFilterChange()" @deselect="onFilterChange()"
                            :show-labels="false">
                        </Multiselect>
                    </div>
                    <div class="form-field col-md-2 col-sm-12">
                        <label for="stationName">End reasons</label>
                        <Multiselect v-model="selectedEndReasons" :options="endReasons" mode="tags"
                            :close-on-select="false" placeholder="Select end reason(s)" label="name" track-by="name"
                            @select="onFilterChange()" @deselect="onFilterChange()" :show-labels="false">
                        </Multiselect>
                    </div>

                    <div class="form-field col-md-2 col-sm-12">
                        <label for="stationName">Type</label>
                        <Multiselect v-model="selectedTypes" :options="types" mode="tags" :close-on-select="false"
                            placeholder="Select type(s)" label="name" track-by="name" @select="onFilterChange()" @deselect="onFilterChange()"
                            :show-labels="false">
                        </Multiselect>
                    </div>

                    <div class="form-field col-md-2 col-sm-12">
                        <label for="stationName">Driver IDs</label>
                        <Multiselect v-model="selectedDriverIds" :options="driverIds" mode="tags"
                            :close-on-select="false" placeholder="Select driver(s)" label="name" track-by="name"
                            @select="onFilterChange()" @deselect="onFilterChange()" :show-labels="false">
                        </Multiselect>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="form-field col-md-2 col-sm-12">
                        <label for="validFrom">Start Date</label>
                        <input type="date" class="form-control" name="validFrom" v-model="selectedStartTime"
                            placeholder="Choose a date">
                    </div>
                    <div class="form-field col-md-2 col-sm-12">
                        <label for="validTo">End Date</label>
                        <input type="date" class="form-control" name="validFrom" v-model="selectedEndTime"
                            placeholder="Choose a date">
                    </div>
                </div>


            </div>
            <div class="container-fluid mx-3 my-5">
                <div class="row">
                    <div class="form-field col-md-12 col-sm-12 mt-3">
                        <label for="total">Number of reservations: <b>{{ filteredReservations.length
                                }}</b></label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-field col-md-6 col-sm-12 my-3">
                        <b-input-group size="sm">
                            <b-form-input id="filter-input" v-model="filter" type="search"
                                placeholder="Type to search..."></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="form-field col-md-12 col-sm-12">
                        <!-- <vue-bootstrap-table class="reservations-table" :columns="tableColumns"
                                :values="filteredReservations" :show-filter="true" :sortable="true" :paginated="true"
                                :pageSize="20" :row-click-handler=handleRowFunction :multi-column-sortable=true
                                :filter-case-sensitive=false :selectable="false" hover>
                                <template v-slot:title="slotProps">
                                    {{ slotProps.value.title }}
                                </template>
<template v-slot:description="slotProps">
                                    {{ slotProps.value.description }}
                                </template>
</vue-bootstrap-table> -->
                        <b-table :items="filteredReservations" :fields="fields" :current-page="currentPage"
                            per-page="20" :filter="filter" stacked="md" show-empty small hover
                            striped selectable responsive @row-clicked="handleRow" select-mode="single">

                            <template #head()="data">
                                <div class="text-nowrap" style="color: #fff">
                                {{ data.label }}
                                </div>
                            </template>

                            <template #cell(vehicle)="data">
                                {{ data.item.evMake }} {{ data.item.evModel }}, {{ data.item.evYear }}
                            </template>

                            <!-- <template #row-details="row">
                                    <b-card>
                                        <ul>
                                            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                        </ul>
                                    </b-card>
                                </template> -->
                            <template #head="{ fields }">
                                <thead>
                                    <tr class="custom-header">
                                        <th v-for="field in fields" :key="field.key">
                                            {{ field.label }}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template #cell(endReason)="data">
                                <b-badge :variant="getEndReasonBadgeVariant(data.item.endReason)" pill>
                                    {{ data.item.endReason ?? "Not Available" }}
                                </b-badge>

                            </template>
                            <template #cell(status)="data">
                                <b-badge :class="getStatusBadgeClass(data.item.status)" pill>
                                    {{ data.item.status }}
                                </b-badge>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="form-field col-md-3 col-sm-12"></div>
                        <div class="form-field col-md-6 col-sm-12">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="20" align="fill"
                                size="sm" class="my-0"></b-pagination>
                        </div>
                        <div class="form-field col-md-3 col-sm-12"></div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.stations-utilization.page {
    padding: 0px !important;
    width: 100%;
}

.multiselect--active {
    z-index: 999;
}

.reservations-table {
    cursor: pointer;
}

table thead {
    background-color: #4CAF50;
    /* Green background */
    color: white;
    /* White text */
}

/* Alternatively, you can target specific header cells if needed */
table thead th {
    background-color: #4CAF50;
    /* Green background */
    color: white;
    /* White text */
}

.badge-reserved {
    background-color: #264653 !important;
    /* Custom green color */
    color: white;
}

.badge-waiting {
    background-color: #2a9d8f !important;
    /* Custom red color */
    color: white;
}

.badge-charging {
    background-color: #e9c46a !important;
    /* Custom yellow color */
    color: white;
}

.badge-paused {
    background-color: #f4a261 !important;
    /* Custom gray color */
    color: white;
}

.badge-finished {
    background-color: #e76f51 !important;
    /* Custom gray color */
    color: white;
}
</style>
