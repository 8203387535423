<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

import StringUtils from '@/utils/StringUtils'

export default {
  name: 'EvseStatusDetailsPage',
  props: ['notificationsService'],
  data: function() {
    return {
      isLoading: true,
      evseStatus: null,
      meterValues: null,
      activeMeterValue: null,
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast),
      stringUtils: new StringUtils()
    }
  },
  methods: {
    getEvseStatusById: async function() {
      this.evseStatus = await this.trackingService.getEvseStatusForConnector({
        chargeboxId: this.$route.params.chargeboxId,
        connectorId: this.$route.params.connectorId
      })
    },
    getMeterValuesForEvseStatus: async function() {
      this.meterValues = await this.trackingService.getMeterValuesForConnector({
        chargeboxId: this.$route.params.chargeboxId,
        connectorId: this.$route.params.connectorId
      })

      if(this.meterValues && this.meterValues.length) {
        this.activeMeterValue = this.meterValues[0]
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification, [
        'RECEIVED_START_TRANSACTION_REQUEST',
        'RECEIVED_STOP_TRANSACTION_REQUEST',
        'RECEIVED_STATUS_NOTIFICATION_REQUEST',
        'RECEIVED_METER_VALUES_REQUEST'
      ])
    },
    onIncomingNotification: function (notification) {
      // todo: check if notification is related to this station
      if(notification.type === 'RECEIVED_METER_VALUES_REQUEST') {
        this.getMeterValuesForEvseStatus()
      } else {
        this.getEvseStatusById()
      }
    }
  },
  async mounted() {
    this.isLoading = true
    this.connectToChannel()
    await this.getEvseStatusById()
    await this.getMeterValuesForEvseStatus()
    this.isLoading = false
  }
}
</script>

<template>
<div class="evse-status-details page">
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else>
    <div v-if="!evseStatus" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p><strong>evseStatus</strong> not found</p>
    </div>
    <div v-else class="container-fluid">
      <div class="row page-header-container">
        <div class="page-header">
          <h1>{{ this.evseStatus.name }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Port info</h3>
            </div>
            <div class="card-body">
              <ul class="card-list">
                <li>
                  <span class="title">Created At</span>
                  <span class="value">{{ $moment(this.evseStatus.time).format("MM/DD/YYYY HH:mm:ss") }}</span>
                </li>
                <li>
                  <span class="title">Chargebox ID</span>
                  <span class="value">{{ this.evseStatus.chargeboxID || '/' }}</span>
                </li>
                <li>
                  <span class="title">Connector ID</span>
                  <span class="value">{{ this.evseStatus.connectorID || '/' }}</span>
                </li>
                <li>
                  <span class="title">Name</span>
                  <span class="value">{{ this.evseStatus.name || '/' }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Charging info</h3>
              <b-badge v-if="this.evseStatus && this.evseStatus.status" 
                :class="'pill station-status-' + stringUtils.propToClass(this.evseStatus.status)">
                {{ this.evseStatus.status.toUpperCase().replace('', ' ') }}
              </b-badge>
            </div>
            <div class="card-body">
              <ul class="card-list">
                <li>
                  <span class="title">Meter Value</span>
                  <span class="value">
                    {{ 
                      (this.evseStatus.meterValue ? this.evseStatus.meterValue / 1000 : 0).toFixed(3) 
                    }}
                    <strong> kWh</strong>
                  </span>
                </li>
                <li>
                  <span class="title">Reason</span>
                  <span class="value">{{ this.evseStatus.reason || '/' }}</span>
                </li>
                <li>
                  <span class="title">Error Code</span>
                  <span class="value">{{ this.evseStatus.errorCode || '/' }}</span>
                </li>
                <li>
                  <span class="title">Vendor Error Code</span>
                  <span class="value">{{ this.evseStatus.vendorErrorCode || '/' }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Meter Values</h3>
            </div>
            <div :class="'card-body with-inline-list ' + meterValues && meterValues.length ? 'scrollable' : ''">
              <ul v-if="this.activeMeterValue" class="card-list inline">
                <li>
                  <span class="value large">
                    {{ ((this.activeMeterValue || { currentA: 0 }).currentA.toFixed(2) || 0) }}
                    <strong> A</strong>
                  </span>
                  <span class="title">Charge Current</span>
                </li>
                <li>
                  <span class="value large">
                    {{ (((this.activeMeterValue || { power: 0 }).power / 1000).toFixed(2) || 0) }}
                    <strong> kW</strong>
                  </span>
                  <span class="title">Active Power</span>
                </li>
                <li>
                  <span class="value large">
                    {{ ((this.activeMeterValue || { voltageV: 0 }).voltageV.toFixed(2) || 0) }}
                    <strong> V</strong>
                  </span>
                  <span class="title">Line Voltage</span>
                </li>
                <li>
                  <span class="value large">{{ 
                    (((this.activeMeterValue || { energy: 0 }).energy / 1000).toFixed(3) || '0') }}
                    <strong> kWh</strong>
                  </span>
                  <span class="title">Active Energy</span>
                </li>
                <li>
                  <span class="value large">
                    {{ 
                      ((this.activeMeterValue || { soc: 0 }).soc || '0') 
                    }}
                    <strong> %</strong>
                  </span>
                  <span class="title">SOC</span>
                </li>
              </ul>
              <table v-if="meterValues && meterValues.length" class="meter-values-table">
                <thead>
                  <th>Created At</th>
                  <th>Transaction</th>
                  <th>Current</th>
                  <th>Current Offered</th>
                  <th>Energy</th>
                  <th>Power</th>
                  <th>SOC</th>
                  <th>Voltage</th>
                </thead>
                <tbody>
                  <tr v-for="meterValue of meterValues" 
                    :key="meterValue.time">
                    <td>{{ $moment(meterValue.time).format("MM/DD/YYYY HH:mm:ss") }}</td>
                    <td>{{ meterValue.transactionID || '/' }}</td>
                    <td>{{ meterValue.currentA.toFixed(2) }} <strong>A</strong></td>
                    <td>{{ meterValue.currentOfferedA.toFixed(2) }} <strong>A</strong></td>
                    <td>{{ ((meterValue.energy || 0) / 1000).toFixed(3) }} <strong>kWh</strong></td>
                    <td>{{ ((meterValue.power || 0) / 1000).toFixed(3) }} <strong>kW</strong></td>
                    <td>{{ meterValue.soc }} <strong>%</strong></td>
                    <td>{{ meterValue.voltageV.toFixed(2) }} <strong>V</strong></td>
                  </tr>
                </tbody>
              </table>
              <div v-else>
                <div class="no-data">
                  <p>No meter values found</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.page.evse-status-details {
  .row {
    padding: 12px;
  }

  .row.page-header-container {
    padding: 0px!important;
  }
}

.card-body.scrollable {
  overflow-x: scroll;

  table {
    overflow: hidden;
  }
}

.no-data {
  padding: 12px 24px;
  
  p {
    padding: 0px;
    margin: 0 auto;
  }
}

td {
  white-space: pre;

  strong {
    font-size: 12px;
  }
}

.card-list {
    list-style: none;
    margin: 0px auto;
    padding: 32px 24px;

    &.inline {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 0px!important;

        .value, .title {
          text-align: center;
        }

        .title {
          opacity: 0.7;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 800;
        }

        .value.large {
          max-width: 100%!important;
          font-size: 32px;

          strong {
            color: #0371ff !important;
            font-size: 18px;
          }
        }
      }
    }
}
</style>
