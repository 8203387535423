<script>
import StationsService from '../../services/api/ocpp/StationsService'
import ToastService from '../../services/ToastService'

export default {
  name: 'StationModelsListPage',
  data: function() {
    return {
      filters: {
        date: null,
        status: null,
        type: null,
        title: null
      },
      isLoading: false,
      stationsService: new StationsService(this),
      toastService: new ToastService(this.$toast),
      stationsModels: []
    }
  },
  methods: {
    getStationsModels: async function() {
      this.isLoading = true
      this.stationsModels = await this.stationsService.getStationModels()
      if(!this.stationsModels) {
        this.toastService.error('Failed to load stations models')
      }
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getStationsModels()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="stations-models-list page">
    <div v-if="!stationsModels || stationsModels.length === 0" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>stations models</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="stations-models-table">
        <thead>
          <th class="left">Model</th>
          <th>Manufacturer</th>
          <th>EVSE type</th>
          <th>Max amperage</th>
        </thead>
        <tbody>
          <tr v-for="stationModel of stationsModels" 
            :key="stationModel.id">
            <td class="left">{{ stationModel.model }}</td>
            <td>{{ stationModel.manufacturer }}</td>
            <td>{{ stationModel.evseType }}</td>
            <td>{{ stationModel.maxAmperage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stations-models-list.page {
  padding: 0px!important;
  width: 100%;
}
</style>
