'use strict'

import ApiService from '../ApiService'

export default class LoginService extends ApiService {
  async login (username, password) {
    return this._sendRequest({
      url: '/login',
      method: 'POST',
      body: {
        username,
        password
      },
      useCredentials: false
    })
  }
}
