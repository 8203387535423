<script>
export default {
  name: 'GetChargingScheduleModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      connectorId: '',
      connectorIdState: null,
      duration: '',
      durationState: null,
      chargingRateUnit: '',
      chargingRateUnitState: null,
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      this.connectorIdState = valid ? true : false
      this.durationState = valid ? true : false
      this.chargingRateUnitState = true
      return valid
    },
    resetModal: function() {
      this.connectorId = ''
      this.connectorIdState = null
      this.duration = ''
      this.durationState = null
      this.chargingRateUnit = ''
      this.chargingRateUnitState = null
      this.$emit("closed")
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
        
      this.$emit("submit", {
        connectorId: this.connectorId,
        duration: this.duration,
        chargingRateUnit: this.chargingRateUnit
      })

      this.$nextTick(() => {
        this.$refs.getChargingScheduleModal.hide()
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.getChargingScheduleModal.show()
      } else {
        this.$refs.getChargingScheduleModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="get-charging-schedule modal">
    <b-modal
      id="get-charging-schedule"
      ref="getChargingScheduleModal"
      title="Get Composite Charging Profile"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="connectorIdState"
          label="Connector ID*"
          label-for="connector-id-input"
          invalid-feedback="Connector ID is required">
          <b-form-input
            id="connector-id-input"
            v-model="connectorId"
            :state="connectorIdState"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="durationState"
          label="Duration*"
          label-for="duration-input"
          invalid-feedback="Duration is required">
          <b-form-input
            id="duration-input"
            v-model="duration"
            :state="durationState"
            required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="chargingRateUnitState"
          label="Charging Rate Unit"
          label-for="charging-rate-unit-input">
          <b-form-select
            id="charging-rate-unit-input"
            v-model="chargingRateUnit"
            :state="chargingRateUnitState">
            <option value="">
            </option>
            <option value="W">
              W
            </option>
            <option value="A">
              A
            </option>
          </b-form-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>