<script>
import SimulatorService from '../../services/api/ocpp/SimulatorService'
import ToastService from '../../services/ToastService'

export default {
  name: 'SessionRegisterPage',
  data: function() {
    return {
      isLoading: false,
      isSimulatorStarted: false,
      simulatorService: new SimulatorService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    getSimulatorStatus: async function() {
      this.isLoading = true
      const response = await this.simulatorService.getStatus()

      if(response) {
        this.isSimulatorStarted = response.started
      } else {
        this.toastService.error("Failed to get simulator status")
      }

      this.isLoading = false
    },
    startSimulator: async function() {
      this.isLoading = true
      const response = await this.simulatorService.start()

      if(response) {
        this.isSimulatorStarted = response.started
      } else {
        this.toastService.error("Failed to start simulator")
      }

      this.isLoading = false
    },
    stopSimulator: async function() {
      this.isLoading = true
      const response = await this.simulatorService.stop()

      if(response) {
        this.isSimulatorStarted = response.started
      } else {
        this.toastService.error("Failed to stop simulator")
      }

      this.isLoading = false
    }
  },
  mounted() {
    this.getSimulatorStatus();
  }
}
</script>

<template>
  <div class="simulator page">
    <div v-if="!isSimulatorStarted" 
      class="start-simulator">
      <p v-if="isLoading">Starting simulator, please wait...</p>
      <p v-else>Simulator not started yet!</p>
      <button @click="startSimulator()" 
        class="btn btn-start">
        Start simulator
      </button>
    </div>
    <div v-else class="stop-simulator">
      <p v-if="isLoading">Stopping simulator, please wait...</p>
      <p v-else>Simulator up and running!</p>
      <button @click="stopSimulator()" 
        class="btn btn-stop">
        Stop simulator
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.start-simulator,
.stop-simulator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  display: block;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 32px;
  color: #fff!important;

  &.btn-start {
    background-color: #218838;
  }

  &.btn-stop {
    background-color: #DC3545;
  }
}
</style>
