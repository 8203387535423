<script>
import StationsService from '@/services/api/ocpp/StationsService'
import ToastService from '../../services/ToastService'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ShowCalendarEventModal from '../../components/modals/ShowCalendarEventModal'
//import VueMultiselect from 'vue-multiselect'
import Multiselect from '@vueform/multiselect'
import TrackingService from '../../services/api/ocpp/TrackingService'

export default {
  name: 'UtilizationPage',
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    ShowCalendarEventModal,
    //VueMultiselect
    Multiselect
  },
  data: function () {
    return {
      selectedGroups: [],
      stations: {},
      groups: {},
      stationList: [],
      groupList: [],
      filteredStationList: [],
      isLoading: false,
      stationsService: new StationsService(this),
      groupsService: new TrackingService(this),
      selectedStations: [],
      selectedDates: null,
      toastService: new ToastService(this.$toast),
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth' //,timeGridWeek,timeGridDay'
        },
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
        },
        timeZone: 'local',
        initialView: 'dayGridMonth',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        dateClick: this.handleDateClick,
        editable: true,
        customButtons: {
          prev: {
            text: 'Prev',
            click: this.handlePrevClick
          },
          next: {
            text: 'Next',
            click: this.handleNextClick
          },
        },
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          timeGrid: {
            dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
          }
        }
      },
      eventsData: [],
      utilizationData: null,
      isShowCalendarEventModalVisible: false,
    }
  },
  methods: {
    getGroups: async function () {
      const groups = await this.groupsService.getGroups();
      if (!groups)
        return

      this.groups = {}
      this.stations = {}
      for (const group of groups) {
        this.groups[group.id] = group

        this.groupList.push({
          name: group.name,
          value: group.id
        })

        for (const station of group.stations) {
          this.stations[station.id] = station

          this.stationList.push({
            name: station.name,
            value: station.id,
            groupId: group.id
          })
        }

        this.filteredStationList = this.stationList
      }
    },
    getUtilization: async function (stationId, startTime, endTime) {
      const stationUtilization = await this.stationsService.getUtilization(stationId, startTime, endTime);
      if (!stationUtilization)
        return

      return stationUtilization
    },
    handleDateClick: () => {
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    handleDateSelect(selectInfo) {
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection
    },

    async handlePrevClick() {
      const calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.prev()
      this.refreshUtilization()
    },

    async handleNextClick() {
      const calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.next()
      this.refreshUtilization()
    },

    handleEventClick(eventData) {
      this.utilizationData = this.getUtilizationById(eventData.event.id)
      console.log(`Utilization data`, this.utilizationData)
      this.showCalendarEventModal()
    },

    handleEvents() {
    },
    getUtilizationById(reservationId) {
      return this.eventsData.find(event => event.id == reservationId)
    },
    async onStationChange() {
      // On this occassion remove the events as context has changed.
      const calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.removeAllEvents()

      await this.refreshUtilization()
    },
    showCalendarEventModal: function () {
      this.isShowCalendarEventModalVisible = true
    },
    async refreshUtilization() {
      const calendarApi = this.$refs.fullCalendar.getApi()
      let stationIds = []

      for (const selectedStation of this.selectedStations) {
        const stationData = this.stations[selectedStation]
        if (!stationData) {
          continue
        }

        // Display only stations belonging to those groups in the filtered station list
        stationIds.push(stationData.id)
      }

      if(!stationIds.length) {
        // TODO: If group is selected, get all stations from the group?
        console.log("No stations selected.")
        return
      }

      this.selectedDates = calendarApi.currentData.dateProfile.currentRange
      this.eventsData = await this.getUtilization(stationIds, this.selectedDates.start, this.selectedDates.end)
      for (const eData of this.eventsData) {
        const title = `${eData.firstName[0]} ${eData.lastName}`
        calendarApi.addEvent({
          id: eData.id,
          title: title,
          start: eData.startTime,
          end: eData.endTime,
        })
      }
    },
    onGroupChange() {
      // If no group is selected, show all stations in the dropdown
      if (this.selectedGroups.length === 0) {
        this.filteredStationList = this.stationList
        return
      }

      this.filteredStationList = []

      this.showStationsFromSelectedGroup()

      // Find all selected groups
      for (const selectedGroup of this.selectedGroups) {
        console.log(this.groups)
        console.log(selectedGroup)
        const groupData = this.groups[selectedGroup]
        if (!groupData) {
          continue
        }

        // Display only stations belonging to those groups in the filtered station list
        const stations = groupData.stations

        for (const station of stations) {
          this.filteredStationList.push({
            name: station.name,
            value: station.id,
            groupId: groupData.id
          })
        }
      }

    },

    showStationsFromSelectedGroup() {
      const stationsFromSelectedGroup = []
      for (const selectedStation of this.selectedStations) {
        const groupId = selectedStation.groupId
        for (const selectedGroup of this.selectedGroups) {
          if (groupId === selectedGroup) {
            stationsFromSelectedGroup.push(selectedStation)
            break
          }
        }
      }

      this.selectedStations = stationsFromSelectedGroup
    }

  },
  async mounted() {
    this.isLoading = true
    await this.getGroups()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="utilization-list page">
    <div v-if="!stations || stations.length === 0" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>stations</strong> found</p>
    </div>
    <div v-else>
      <div class="row page-header-container">
        <div class="page-header">
          <h1>Station utilization</h1>
        </div>
      </div>
      <div class="row">
        <div class="form-field col-md-4 col-sm-12 mx-3 my-5" style="z-index: 999;">
          <!-- <label for="stationName">List of stations</label>
          <select class="form-control" name="stationName" placeholder="Pick a station" @change="onStationChange()"
            v-model="selectedStations" required>
            <option disabled value="">Pick a station</option>
            <option v-for="station of this.stations" :key="station.id"
              :class="{ station, chargebox_id: station.chargeboxID }" :value="station.name"> {{ station.name }}
            </option>
          </select> -->
          <label for="groupName">List of groups</label>
          <Multiselect v-model="selectedGroups" :options="groupList" mode="tags" :close-on-select="false"
            :searchable="true" placeholder="Select group(s)" label="name" track-by="name" @select="onGroupChange()">
          </Multiselect>
        </div>

        <div class="form-field col-md-4 col-sm-12 mx-3 my-5 " style="z-index: 999;">
          <label for="stationName">List of stations</label>
          <Multiselect v-model="selectedStations" :options="filteredStationList" mode="tags"
            :close-on-select="false" :searchable="true" placeholder="Select station(s)" label="name" track-by="name"
            @select="onStationChange()"
            >
          </Multiselect>
        </div>

      </div>
      <div class="form-field mt-20 mx-3">
        <FullCalendar :options="calendarOptions" ref="fullCalendar" />
      </div>

    </div>

    <show-calendar-event-modal :visible="this.isShowCalendarEventModalVisible" :utilizationData="this.utilizationData"
      @closed="() => { this.isShowCalendarEventModalVisible = false }">
    </show-calendar-event-modal>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.stations-utilization.page {
  padding: 0px !important;
  width: 100%;
}

.fc-day-top {
  text-align: center;
}

.multi-line {
  white-space: pre-line;
}

.fc-event {
  cursor: pointer;
}
</style>
