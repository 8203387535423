'use strict'

import ApiService from '../ApiService'

export default class StationsService extends ApiService {
  async getStations() {
    return this._sendRequest({
      url: '/stations'
    })
  }

  async getStationById(stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}`
    })
  }
  async getStationModels() {
    return this._sendRequest({
      url: `/stations/models`
    })
  }

  async getStationModelById(modelId) {
    return this._sendRequest({
      url: `/stations/models/${modelId}`
    })
  }

  async getStationModelByStationId(stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/model`
    })
  }

  async addStation(station) {
    return this._sendRequest({
      url: '/stations/add',
      method: 'POST',
      body: station
    })
  }

  async updateStation(station) {
    return this._sendRequest({
      url: '/stations/update',
      method: 'PUT',
      body: station
    })
  }

  async deleteStation(stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/delete`,
      method: 'POST'
    })
  }

  async getSmartChargingProfiles() {
    return this._sendRequest({
      url: `/stations/chargingProfiles`,
    })
  }

  async addSmartChargingProfile(name, chargingProfile) {
    return this._sendRequest({
      url: `/stations/chargingProfiles/add`,
      method: 'POST',
      params: {
        name,
      },
      body: chargingProfile
    })
  }

  async getSmartChargingProfileById(chargingProfileId) {
    return this._sendRequest({
      url: `/stations/chargingProfiles/${chargingProfileId}`,
    })
  }

  async updateSmartChargingProfile(chargingProfileId, name, chargingProfile) {
    return this._sendRequest({
      url: `/stations/chargingProfiles/${chargingProfileId}/update`,
      method: 'POST',
      params: {
        name,
      },
      body: chargingProfile
    })
  }

  async deleteSmartChargingProfile(chargingProfileId) {
    return this._sendRequest({
      url: `/stations/chargingProfiles/${chargingProfileId}/delete`,
      method: 'POST',
    })
  }

  async getUtilization(stationIds, startTime, endTime) {
    const stationIdsString = stationIds.join(',');
    return this._sendRequest({
      url: `/stations/utilization`,
      params: {
        startTime,
        endTime,
        stationIds: stationIdsString
      }
    })
  }

  async getStationGroups() {
    return this._sendRequest({
      url: `/groups`,
    })
  }

  async uploadStationImage(stationId, image) {
    const formData = new FormData();
    formData.append('image', image);

    return this._sendRequest({
      url: `/stations/${stationId}/uploadImage`,
      method: 'POST',
      body: formData
    })
  }

  async removeStationImage(stationId) {
    return this._sendRequest({
      url: `/stations/${stationId}/removeImage`,
      method: 'POST'
    })
  }
}
