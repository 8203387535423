<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'vue-toast-notification/dist/theme-bootstrap.css';

import NavigationBar from './components/navigation/NavigationBar'
import NavigationMenu from './components/navigation/NavigationMenu'
import NotificationsChannel from './components/navigation/NotificationsChannel'

import NotificationsService from './services/api/ocpp/NotificationsService'

export default {
  name: 'app',
  components: {
    NavigationBar,
    NavigationMenu,
    NotificationsChannel
  },
  data: function() {
    const isNavigationCollapsed = localStorage.getItem('isNavigationCollapsed')
    const isNotificationsChannelCollapsed = localStorage.getItem('isNotificationsChannelCollapsed')

    let notificationsService = new NotificationsService(this)
    notificationsService.registerCallback(this.onIncomingNotification)

    return {
      notificationsService,
      isNavigationCollapsed: isNavigationCollapsed && isNavigationCollapsed === 'true',
      isNotificationsChannelCollapsed: isNotificationsChannelCollapsed && isNotificationsChannelCollapsed === 'true'
    }
  },
  methods: {
    onNavigationToggle: function(isNavigationCollapsed) {
      this.isNavigationCollapsed = isNavigationCollapsed
      localStorage.setItem('isNavigationCollapsed', isNavigationCollapsed)
    },
    onNotificationChannelToggle: function(isNotificationsChannelCollapsed) {
      this.isNotificationsChannelCollapsed = isNotificationsChannelCollapsed
      localStorage.setItem('isNotificationsChannelCollapsed', isNotificationsChannelCollapsed)
    },
    onIncomingNotification: function (notification) {
      console.log('incoming app', notification)
    }
  },
  computed: {
    isLoginPage: function() {
      return this.$route.path.includes('login');
    }
  },
}
</script>

<template>
  <div id="app">
    <navigation-bar
     v-if="!isLoginPage"
     :isNavigationCollapsed="isNavigationCollapsed"
     :isNotificationsChannelCollapsed="isNotificationsChannelCollapsed"
     :onNavigationToggle="onNavigationToggle"
     :onNotificationChannelToggle="onNotificationChannelToggle" />
    <navigation-menu 
     v-if="!isLoginPage"
      :isCollapsed="isNavigationCollapsed" />
      <div 
        :class="{ 
          'page-container': true, 
          'login': this.isLoginPage,
          'extended-left': this.isNavigationCollapsed ,
          'extended-right': this.isNotificationsChannelCollapsed 
        }">
      <router-view 
        :notificationsService="notificationsService" />
    </div>
    <notifications-channel
      v-if="!isLoginPage"
      :isCollapsed="isNotificationsChannelCollapsed"
      :notificationsService="notificationsService" />
  </div>
</template>

<style lang="scss">
.hidden {
  display: none!important;
}

* { 
  margin: 0; 
  font-family: 'Bai Jamjuree', sans-serif;
}

html, body, #app {
  height: 100% !important;
  background-color:rgb(30, 38, 47);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

button:focus, button:active,
input:focus, input:active {
  outline-color: transparent!important;
  outline: none!important;
  box-shadow: none!important;
  outline-style:none!important;
}

thead, th {
  position: sticky!important;
  top: 0;
  z-index: 550;
}

.modal.fade {
  .modal-dialog {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px auto;
    transform: translateY(-100%);
    transition: all 0.3s linear;
  }

  &.show .modal-dialog {
    opacity: 1;
    transition: all 0.3s linear;
    transform: translateY(0);
  }
}

.loading-indicator {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px auto;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;

  p {
    font-size: 18px;
  }
}

.page-container {
  overflow-x: auto;
  overflow-y: auto;
  position: fixed;
  left: 260px;
  right: 260px;
  top: 56px;
  padding-top: 0px;
  bottom: 0;
  margin: 0px auto;
  z-index: 500;
  box-shadow: 0px 16px 24px rgb(0, 0, 0);
  background-color: rgb(245, 245, 245);
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  transition: all 0.3s linear;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  &.login {
    padding-top: 0px;
    left: 0;
    right: 0;
  }

  &.extended-left {
    left: 0;
  }

  &.extended-right {
    right: 0;
  }
}

.station-edit.page, .station-listpage {
    padding: 24px;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.badge {
  padding: 8px 16px;
  font-size: 10px;
  font-weight: 800;
  border-radius: 24px;
  min-width: 92px;

  &.success {
    background-color: #218838!important;
    color: #fff!important;
  }

  &.error {
    background-color: #DC3545!important;
    color: #fff!important;
  }

  &.warning {
    background-color: #ffc107!important;
    color: #000!important;
  }
}

.pill {

  &.activation-status-active {
    background-color: #218838!important;
    color: #fff!important;
  }

  &.activation-status-inactive {
    background-color: #bfbfbf!important;
    color: #000!important;
  }

  &.network-status-online {
    background-color: #218838!important;
    color: #fff!important;
  }

  &.network-status-offline {
    background-color: #DC3545!important;
    color: #fff!important;
  }

  &.station-status-active {
    background-color: #218838!important;
    color: #fff!important;
  }

  &.station-status-available {
    background-color: #2f64f5!important;
    color: #fff!important;
  }

  &.station-status-preparing {
    background-color: #91d491!important;
    color: #000!important;
  }

  &.station-status-charging {
    background-color: #218838!important;
    color: #fff!important;
  }

  &.station-status-suspendedev {
    background-color: darkkhaki!important;
    color: #000!important;
  }

  &.station-status-finishing {
    background-color: #009494!important;
    color: #fff!important;
  }

  &.station-status-reserved {
    background-color: orange!important;
    color: #000!important;
  }

  &.station-status-unavailable {
    background-color: #bfbfbf!important;
    color: #000!important;
  }

  &.station-status-faulted {
    background-color: darkred!important;
    color: #fff!important;
  }

  &.station-status-inactive {
    background-color: #bfbfbf!important;
    color: #000!important;
  }

  &.notification-direction-system {
    background-color: transparent!important;
    text-align: left;
    color: #5c82b3!important;
    padding: 8px 0 8px 0px;
    font-size: 12px;
    font-weight: 900;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  &.notification-direction-inbound {
    color: #4f89ff!important;
    background-color: transparent!important;
    text-align: left;
    padding: 8px 0 8px 0px;
    font-size: 12px;
    font-weight: 900;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  &.notification-direction-outbound {
    color: #d883ff!important;
    background-color: transparent!important;
    text-align: left;
    padding: 8px 0 8px 0px;
    font-size: 12px;
    font-weight: 900;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }
}

td {
  .notification-direction-system,
  .notification-direction-inbound,
  .notification-direction-outbound {
    text-align: center !important;
    justify-content: center !important;
  }
}

.modal-body p {
  margin: 0px auto;
}

.modal-footer {
  &.delete-station-modal-footer {
    .btn-primary {
      background-color: #DC3545!important;
      border-color: #DC3545!important;
    }
  }

  &.cancel-station-edit-modal-footer {
    .btn-primary {
      background-color: #ffc107!important;
      border-color: #ffc107!important;
      color: #000;
    }
  }
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  img {
    height: 120px;
    width: 120px;
  }

  p {
    margin: 0px auto;
    padding: 0px;

    strong {
      color: rgb(59, 143, 251);
    }
  }
}

.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #218838;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.page {

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 32px;
    background-color: rgb(30, 38, 47);
    color: #fff;
    // position: sticky;
    // left: 0;
    // right: 0;

    h1 {
      font-size: 18px;
      font-weight: 700;
      margin: 0px!important;
      padding: 0px!important;
    }

    .main-command-btn {
      margin-left: 12px;
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
      background-color: transparent!important;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      img {
        height: 16px;
        width: 16px;
        margin-right: 6px;
      }
    }
  }
  
  table {
    width: 100%;
    color: #000!important;
    font-weight: 500;

    th.center,
    td.center {
      text-align: center;
    }

    th.left,
    td.left {
      text-align: left;
    }
    
    thead {
      background-color: rgb(30, 38, 47);
      color: rgb(245, 245, 245);

      th {
        background-color: rgb(30, 38, 47);
        padding: 12px 16px;
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        vertical-align: middle;
        text-align: center;
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: rgb(236, 236, 236);
      }

      tr td {
        padding: 12px 16px;
        font-size: 15px;
        vertical-align: middle;
        text-align: center;

        strong {
          color: #0371ff !important;
        }

        svg {
          height: 20px;
          width: 20px;
        }

        a {
          margin: 0 auto;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        button {
          font-size: 12px;
          font-weight: 800;
          text-transform: uppercase;
          margin: 0 auto;
          padding: 0px;
          background-color: transparent;
          border: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }


.card {
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.3);
  margin: 16px 0px;
  border: 0px solid rgba(0, 0, 0, 0.2)!important;
  border-radius: 0px!important;

  .card-header {
    background-color: rgb(30, 38, 47);
    border: 0px!important;
    border-radius: 0px!important;
    color: #fff;
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;

    h3 {
      margin: 0px!important;
      text-align: left!important;
      padding: 2px 0px!important;
      font-size: 14px;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .card-body {
    padding: 0px;

    .no-data-found {
      justify-content: flex-start!important;
      align-items: flex-start;
      text-align: left !important;
      padding: 12px 18px;
      margin: 0px auto !important;
      font-size: 14px;

      p {
        margin: 0px!important;
      }
    }

    .card-list {
      list-style: none;
      margin: 0px auto;
      padding: 0px;

      &.inline {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border-bottom: 0px!important;

          .value, .title {
            text-align: center;
          }

          .title {
            opacity: 0.7;
          }

          .value.large {
            max-width: 100%!important;
            font-size: 32px;

            strong {
              color: #0371ff !important;
              font-size: 18px;
            }
          }
        }
      }

      li {
        padding: 12px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);    
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          color: #1972e4!important;
        }

        &:last-child {
          border-bottom: 0px;
        }

        .title {
          font-weight: 800;
          font-size: 14px;
        }

        .value {
          font-weight: 400;
          font-size: 14px;
          text-align: right;
          max-width: 70%;
        }
      }
    }
  }
}
}
</style>
