<script>
import VueJsonPretty from 'vue-json-pretty'

import ToastService from '../../services/ToastService'
import StationsService from '../../services/api/ocpp/StationsService'

export default {
  name: 'NotificationDetailsPage',
  props: [ 'notificationsService' ],
  components: {
    VueJsonPretty
  },
  data: function() {
    return {
      isLoading: true,
      notification: null,
      station: null,
      payloadPreview: 'table',
      toastService: new ToastService(this.$toast),
      stationsService: new StationsService(this)
    }
  },
  methods: {
    getNotificationById: async function() {
      this.isLoading = true;

      if(this.$route.params.id) {
        this.notification = await this.notificationsService.getNotificationById(
          this.$route.params.id)

        if(!this.notification) {
          this.toastService.error('Notification not found')
          this.isLoading = false
        } else {
          this.getStation()
        }
      } else {
        this.toastService.error('Notification not found')
        this.isLoading = false
      }
    },
    getStation: async function() {
      if(this.notification && this.notification.stationId) {
        this.station = await this.stationsService.getStationById(this.notification.stationId)
      }
      this.isLoading = false
    },
    toggleViewCode: function() {
      this.payloadPreview = this.payloadPreview === 'table' 
        ? 'code' 
        : 'table'
    },
    getNotificationPayload: function(notification) {
      if(!notification || !notification.payload) {
        return {}
      }

      return notification.payload 
        && typeof(notification.payload) === 'string'
          ? JSON.parse(notification.payload)
          : notification.payload
    },
    getNotificationData: function(notification) {
      if(!notification && !notification.payload) {
        return {}
      }

      const payload = typeof(notification.payload) === 'string'
          ? JSON.parse(notification.payload)
          : notification.payload

      if(payload && payload.data) {
        return this.isJsonObject(payload.data)
            ? JSON.parse(payload.data)
            : payload.data
      } else {
        if(payload && !payload.data && !payload.request && !payload.response) {
          return payload
        }
      }
    },
    getNotificationRequest: function(notification) {
      if(!notification && !notification.payload) {
        return {}
      }

      const payload = typeof(notification.payload) === 'string'
          ? JSON.parse(notification.payload)
          : notification.payload

      if(payload && payload.request) {
        return this.isJsonObject(payload.request)
            ? JSON.parse(payload.request)
            : payload.request
      } else {
        return null
      }
    },
    getNotificationResponse: function(notification) {
      if(!notification && !notification.payload) {
        return {}
      }

      const payload = typeof(notification.payload) === 'string'
          ? JSON.parse(notification.payload)
          : notification.payload

      if(payload && payload.response) {
        return this.isJsonObject(payload.response)
            ? JSON.parse(payload.response)
            : payload.response
      } else {
        return null
      }
    },
    isJsonObject: function(obj) {
      if(!obj) {
        return false
      }
      
      return typeof(obj) === 'string'
        && (obj.startsWith("[") || obj.startsWith("{"))
        && (obj.startsWith("]") || obj.startsWith("}"))
    }
  },
  mounted() {
    this.getNotificationById();
  },
  watch: {
    $route () {
      this.getNotificationById();
    }
  } 
}
</script>

<template>
<div class="notification-details page">
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else>
    <div v-if="!notification" class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p><strong>Notification</strong> not found</p>
    </div>
    <div v-else class="container-fluid">
      <div class="row page-header-container">
        <div class="page-header">
          <h1>{{ this.notification.title }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Notification info</h3>
              <b-badge :class="this.notification.status ? this.notification.status.toLowerCase() : 'none'">
                {{ notification.status.toUpperCase().replace('_',' ') }}
              </b-badge>
            </div>
            <div class="card-body">
              <ul class="card-list">
                <li>
                  <span class="title">Created At</span>
                  <span class="value">{{ $moment(notification.date).format("HH:mm:ss, MMMM Do YYYY") }}</span>
                </li>
                <li>
                  <span class="title">Title</span>
                  <span class="value">{{ this.notification.title || '/' }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Station info</h3>
            </div>
            <div class="card-body">
              <ul class="card-list">
                <li>
                  <span class="title">Session ID</span>
                  <span class="value">{{ this.notification.socketId || '/' }}</span>
                </li>
                <li v-if="this.station">
                  <span class="title">Station</span>
                  <span class="value">
                    <router-link
                      :to="`/stations/${this.station.id}`">
                      {{ this.station.chargeboxID || this.station.name || 'View station details' }}
                    </router-link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="getNotificationData(this.notification)">
        <div class="col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Data</h3>
              <button @click="toggleViewCode()" class="btn-view-code">
                <img v-if="payloadPreview === 'table'" src="../../assets/actions/code.svg">
                <img v-else src="../../assets/actions/list.svg">
              </button>
            </div>
            <div class="card-body">
              <div v-if="payloadPreview === 'table'">
                <ul v-if="typeof(getNotificationData(notification)) === 'object'" class="card-list">
                  <li v-for="payloadKey of Object.keys(getNotificationData(notification))" 
                    :key="payloadKey">
                    <span class="title">{{ payloadKey }}</span>
                    <span class="value">{{ getNotificationData(notification)[payloadKey]}}</span>
                  </li>
                </ul>
                <div v-else>
                  <ul class="card-list">
                    <li>
                      <span class="title">Data</span>
                      <span class="value">{{ getNotificationData(notification) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
                <div v-else class="code-preview">
                  <vue-json-pretty
                    :data="getNotificationData(notification)">
                  </vue-json-pretty>
                </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row" v-if="getNotificationRequest(this.notification)">
        <div class="col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Request</h3>
              <button @click="toggleViewCode()" class="btn-view-code">
                <img v-if="payloadPreview === 'table'" src="../../assets/actions/code.svg">
                <img v-else src="../../assets/actions/list.svg">
              </button>
            </div>
            <div class="card-body">
              <div v-if="payloadPreview === 'table'">
                <ul v-if="typeof(getNotificationRequest(notification)) === 'object'" class="card-list">
                  <li v-for="payloadKey of Object.keys(getNotificationRequest(notification))" 
                    :key="payloadKey">
                    <span class="title">{{ payloadKey }}</span>
                    <span class="value">{{ getNotificationRequest(notification)[payloadKey]}}</span>
                  </li>
                </ul>
                <div v-else>
                  <ul class="card-list">
                    <li>
                      <span class="title">Data</span>
                      <span class="value">{{ getNotificationRequest(notification) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
                <div v-else class="code-preview">
                  <vue-json-pretty
                    :data="getNotificationRequest(notification)">
                  </vue-json-pretty>
                </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row" v-if="getNotificationResponse(this.notification)">
        <div class="col-sm-12">
          <div class="card info">
            <div class="card-header">
              <h3>Response</h3>
              <button @click="toggleViewCode()" class="btn-view-code">
                <img v-if="payloadPreview === 'table'" src="../../assets/actions/code.svg">
                <img v-else src="../../assets/actions/list.svg">
              </button>
            </div>
            <div class="card-body">
              <div v-if="payloadPreview === 'table'">
                <ul v-if="typeof(getNotificationResponse(notification)) === 'object'" class="card-list">
                  <li v-for="payloadKey of Object.keys(getNotificationResponse(notification))" 
                    :key="payloadKey">
                    <span class="title">{{ payloadKey }}</span>
                    <span class="value">{{ getNotificationResponse(notification)[payloadKey]}}</span>
                  </li>
                </ul>
                <div v-else>
                  <ul class="card-list">
                    <li>
                      <span class="title">Data</span>
                      <span class="value">{{ getNotificationResponse(notification) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
                <div v-else class="code-preview">
                  <vue-json-pretty
                    :data="getNotificationResponse(notification)">
                  </vue-json-pretty>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.page.notification-details {
  .row {
    padding: 12px;
  }
  .row.page-header-container {
    padding: 0px!important;
  }
}

.btn-view-code {
  background-color: transparent!important;
  border: 0px!important;
  box-shadow: unset!important;
  outline: none!important;
}

.code-preview {
  padding: 16px;
}
</style>
