<script>
import TrackingService from '../../services/api/ocpp/TrackingService'
import ToastService from '../../services/ToastService'

export default {
  name: 'GroupPage',
  data: function() {
    return {
      isLoading: true,
      groups: null,
      trackingService: new TrackingService(this),
      toastService: new ToastService(this.$toast)
    }
  },
  methods: {
    getGroups: async function() {
      this.groups = await this.trackingService.getGroups()

      if(!this.groups) {
        this.toastService.error('Failed to fetch groups')
      }
    },
    connectToChannel: function () {
      this.notificationsService.registerCallback(this.onIncomingNotification, [
        'CHARGE_POINT_CONNECTED'
      ])
    },
    onIncomingNotification: function () {
      // update session registers
      this.getGroups()
    }
  },
  async mounted() {
    this.isLoading = true
    await this.getGroups()
    this.isLoading = false
  }
}
</script>

<template>
  <div v-if="isLoading" class="loading-indicator">
    <p>Loading data, please wait...</p>
  </div>
  <div v-else class="groups page">
    <div v-if="!groups || groups.length === 0" 
      class="no-data-found">
      <img src="../../assets/actions/no_data.svg">
      <p>No <strong>groups</strong> found</p>
    </div>
    <div v-else>
      <div class="filters">
      </div>
      <table class="groups-table">
        <thead>
          <th>Gruop ID</th>
          <th>Name</th>
          <th>Access control</th>
          <th>Peak management</th>
          <th>Current peak</th>
          <th>Power peak</th>
        </thead>
        <transition-group tag="tbody" name="session-register-list" move-class="new">
          <tr v-for="group of groups" 
            :key="group.id">
            <td>{{ group.id }}</td>
            <td>{{ group.name }}</td>
            <td>{{ group.isAccessControlEnabled == 'Enable' ? 'Enabled' :  group.isAccessControlEnabled}}</td>
            <td>{{ group.isPeakManagementEnabled == 'Enable' ? 'Enabled' : group.isPeakManagementEnabled}}</td>
            <td>{{ group.currentPeak }}</td>
            <td>{{ group.powerPeak }}</td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.session-register.page {
  padding: 0px!important;
  width: 100%;
}
</style>
