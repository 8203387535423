<script>
export default {
  name: 'TriggerMessageModal',
  props: {
    visible: Boolean,
    onCollapse: Function
  },
  data: function() {
    return {
      triggerMessageType: '',
      triggerMessageTypeState: null,
      connectorId: '',
      connectorIdState: null,
    }
  },
  methods: {
    checkFormValidity: function() {
      const valid = this.$refs.form.checkValidity()
      this.triggerMessageTypeState = valid
      const connectorId = Number(this.connectorId)
      if (this.connectorId === '' || (Number.isInteger(connectorId) && connectorId > 0))
        this.connectorIdState = valid
      else
        this.connectorIdState = false
      return this.triggerMessageTypeState && this.connectorIdState
    },
    resetModal: function() {
      this.triggerMessageTypeState = null
      this.connectorId = ''
      this.connectorIdState = null
      this.$emit("closed")
    },
    handleOk: function(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit: function() {
      if (!this.checkFormValidity()) {
        return
      }
        
      this.$emit("submit", {
        triggerMessageType: this.triggerMessageType,
        connectorId: this.connectorId
      })

      this.$nextTick(() => {
        this.$refs.triggerMessageModal.hide()
      })
    }
  },
  watch: {
    visible: function(value) {
      if(value) {
        this.$refs.triggerMessageModal.show()
      } else {
        this.$refs.triggerMessageModal.hide()
      }
    }
  }
}
</script>

<template>
  <div class="trigger-message modal">
    <b-modal
      id="trigger-message-modal"
      ref="triggerMessageModal"
      title="Send trigger message request"
      @cancel="resetModal"
      @close="resetModal"
      @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="triggerMessageTypeState"
          label="Trigger Message Type*"
          label-for="trigger-message-type"
          invalid-feedback="TriggerMessageType is required">
          <b-form-select
            id="trigger-message-type"
            v-model="triggerMessageType"
            :state="triggerMessageTypeState"
            required>
            <option value="BootNotification">
              BootNotification
            </option>
            <option value="DiagnosticsStatusNotification">
              DiagnosticsStatusNotification
            </option>
            <option value="FirmwareStatusNotification">
              FirmwareStatusNotification
            </option>
            <option value="Heartbeat">
              Heartbeat
            </option>
            <option value="MeterValues">
              MeterValues
            </option>
            <option value="StatusNotification">
              StatusNotification
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          :state="connectorIdState"
          label="Connector ID"
          label-for="connector-id-input"
          invalid-feedback="Connector ID > 0">
          <b-form-input
            id="connector-id-input"
            v-model="connectorId"
            :state="connectorIdState">
          </b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>