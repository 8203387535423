'use strict'

import ApiService from '../ApiService'

export default class TrackingService extends ApiService {
  async getEvseStatus () {
    return this._sendRequest({
      url: '/tracking/evseStatus'
    })
  }

  async getMeterValues () {
    return this._sendRequest({
      url: '/tracking/meterValues'
    })
  }

  async getMonthAverages () {
    return this._sendRequest({
      url: '/tracking/monthAverage'
    })
  }

  async getSessionRegisters () {
    return this._sendRequest({
      url: '/tracking/sessionRegister'
    })
  }

  async getEvseStatusForStation (chargeboxId) {
    return this._sendRequest({
      url: '/tracking/evseStatus/find',
      method: 'GET',
      params: {
        chargeboxId
      }
    })
  }

  async getEvseStatusForConnector ({ chargeboxId, connectorId }) {
    return this._sendRequest({
      url: '/tracking/evseStatus/findOne',
      method: 'GET',
      params: {
        chargeboxId,
        connectorId
      }
    })
  }

  async getMeterValuesForConnector ({ chargeboxId, connectorId }) {
    return this._sendRequest({
      url: '/tracking/meterValues/findOne',
      method: 'GET',
      params: {
        chargeboxId,
        connectorId
      }
    })
  }

  async getPorts () {
    return this._sendRequest({
      url: '/tracking/ports',
      method: 'GET'
    })
  }

  async getGroups () {
    return this._sendRequest({
      url: '/tracking/groups',
      method: 'GET'
    })
  }

  async getGroupById (groupId) {
    return this._sendRequest({
      url: `/tracking/groups/${groupId}`,
      method: 'GET'
    })
  }

  async getGroupsAccessControls () {
    return this._sendRequest({
      url: '/tracking/groups/accessControl',
      method: 'GET'
    })
  }

  async getGroupAccessControlById (groupAccessControlId) {
    return this._sendRequest({
      url: `/tracking/groups/accessControl/${groupAccessControlId}`,
      method: 'GET'
    })
  }
}
