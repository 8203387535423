import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import App from './App.vue'
import { createBootstrap } from 'bootstrap-vue-next'
import VueToast from 'vue-toast-notification'
import axios from 'axios'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import moment from 'moment';

const app = createApp(App)

app.use(VueToast)
app.use(createBootstrap({components: true, directives: true}))

let router = createRouter({
  routes,
  history: createWebHistory(),
})

// axios.interceptors.request.use(
//   function (config) {
//     const token = window.localStorage.getItem('usertoken')
//     if (token) config.headers.Authorization = `Bearer ${token}`
//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   }
// )

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  if (!error.response || error.response.status === 401) {
    // window.localStorage.removeItem('user')
    // window.localStorage.removeItem('usertoken')
    // router.replace('/login')
  }
  return Promise.reject(error.response)
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

app.config.globalProperties.$moment = moment

app.use(router)

let config = {
  VUE_APP_API_URL: process.env.VUE_APP_API_URL,
  VUE_SENTRY_DNS: process.env.VUE_SENTRY_DNS
};
axios.get('/config.json', ).then(response => {
    console.log('Loaded config:', JSON.stringify(config));
    try {
      if ('VUE_APP_API_URL' in response.data) {
        config.VUE_APP_API_URL = response.data.VUE_APP_API_URL;
      }
      if ('VUE_SENTRY_DNS' in response.data) {
        config.VUE_SENTRY_DNS = response.data.VUE_SENTRY_DNS;
      }
    } catch (error) {
      console.warn('Failed to parse config:', error);
      console.warn('Using default config:', JSON.stringify(config));
    }
}).catch(error => {
  console.warn('Failed to load config:', error);
  console.warn('Using default config:', JSON.stringify(config));
}).finally(() => {
  Sentry.init({
    app,
  
    dsn: config.VUE_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  });

  app.config.globalProperties.$config = config;
  app.mount('#app');
})
